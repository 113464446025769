import React from 'react'
import TableComp from './TableComp'
import { Input, Button, Space, Popconfirm ,Modal, Tag, Switch, Form, Upload, Image} from 'antd'
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words';
import { SearchOutlined ,EditOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { managerLink, deactivateUser, getFrameImages, addFrameImage, updateFrameImage } from '../configurations/urls';
import { Pagination, Spin } from 'antd'
import ClientSetting from './ClientSetting';
import { Store } from 'react-notifications-component';
const { confirm } = Modal;

class CollageFrameTableColumn extends React.Component {

    state = {
        searchText: '',
        isModalVisible:false,
        setEditId:"",
        searchedColumn: '',
        isModalVisible: false,
        visible: false,
        index: 0,
        source: '',
        otherSource: 1,
        sorceData: this.props.dataSource,
        setdealers: [],
        userType : '',
        preview:{ frameFile: ''},
        stateFile:{frameFile: ''},
        imageName:'',
        imageLoading:false
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90, alignItems: 'center', display: 'inline-block' }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    activateDeactivateFrame = (checked, id, name) => {
        let modal_txt = "Are you sure you want to deactivate this frame ?";
        if (checked){
            modal_txt = "Are you sure you want to activate this frame ?"
        }
        confirm ({ 
            icon: <ExclamationCircleOutlined />,
            content: modal_txt,
            className: "confirm-active_modal",
            onOk: () => {
                axios.put(`${getFrameImages}${id}/`, { active: checked, image_name: name })
                    .then(res => {
                        this.setState(prevState => ({
                            source: prevState.source.map(
                                el => el.id === id? { ...el, active: checked }: el
                            )
                        }))
                        Store.addNotification({
                            title: "Status changed successfully",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2500,
                                onScreen: true
                            }
                        });
                    })
                    .catch(err => {
                        Store.addNotification({
                             title: "There is something wrong",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2500,
                                onScreen: true
                            }
                        })
                    })
            }
        })
    };
    columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'Image',
            render: (image, record, index) =><>
                <Image width={80} style={{ margin: '2px', cursor: 'pointer' }}
                    src={record.image_url} />
                
                </>
        },
        {
            title: 'Name',
            dataIndex: 'image_name',
            key: 'Name',
            ...this.getColumnSearchProps('image_name'),
            sorter: (a, b) => { return a.image_name.localeCompare(b.image_name) }
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'CreatedAt',
            sorter: (a, b) => { return a.created_at.localeCompare(b.created_at) }
        },
        {
            title: 'Active',
            key: 'ActiveUser',
            align: 'center',
            render: (record) => {
                return <>
            <Switch size="small" checked={record.active} onChange={(checked) => this.activateDeactivateFrame(checked, record.id, record.image_name)} />
            </>
            }
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (record) =>
                <div className='d-flex align-items-center action_wrap'>
                    <EditOutlined onClick={()=>  {this.handleEdit(record.id)}}  className="ml-2" />
                </div>
        }
    ];
    handleCancel = () => {
        this.setState({isModalVisible: false ,setEditId:''})
        window.location.reload()
    };
    showModal = () => {
        this.setState({isModalVisible: true ,setEditId:''})
    };
    componentDidMount() {
        this.setState({ source: this.props.dataSource });
        this.setState({ otherSource: this.props.otherData });
    }
    componentDidUpdate(prevPros, prevState) {
        if (prevPros.otherData.length !== this.props.otherData.length){
            this.setState({ otherSource: this.props.otherData  })
        }
        if (prevPros.dataSource.length !== this.props.dataSource.length){
            this.setState({ source: this.props.dataSource })
        }
    }
    handlepagination = (page) => {
        axios.get(`${getFrameImages}?page=${page}`)
            .then(res => {
                this.setState({ source: res.data['data'] })
                this.setState({ otherSource: this.otherDataSources(res.data) })

            })
            .catch(err => {
               
            })
    };
    otherdataSources = (data) => {
        let arr = {page:data['page'], total:data['total'], page_size:data['page_size']}
        return arr
    }
    customRequestFun = (options) => {
        const { onSuccess, file } = options;
        this.setState({ stateFile: {frameFile: file }})
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({ preview:{frameFile: reader.result }})
        };
        onSuccess(file)
    };
    onFinish = (values) => {
        this.setState({imageLoading: true})
        let formData = new FormData();
        formData.append('image_url', this.state.stateFile.frameFile);
        formData.append('image_name', values['image_name']);
        formData.append('active', true);
        let put;
        let id = this.state.setEditId;
        let success_text = '';
        if (id) {
            success_text = "Frame updated successfully"
            put = axios.put(`${updateFrameImage}${id}/`, formData)
        }
        else {
            success_text = "Frame added successfully"
            put = axios.post(`${addFrameImage}`, formData)
        }
        put.then(res => {
            this.setState({imageLoading: false})
            Store.addNotification({
                title: success_text,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
            setTimeout(() => {
                window.location.reload();
            }, 2500)
        })
        .catch(err => {
            let errorText = "";
            if (err.response){
                errorText = err.response.data.message
            }else{
                errorText = "Something went wrong"
            }
            this.setState({imageLoading: false})
            Store.addNotification({
                title: errorText,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
        })
    };
    handleEdit = (id) => {
        axios.get(`${getFrameImages}${id}/`)
            .then(async res => {
                let data = res.data;
                this.setState({ preview: {frameFile: data.image_url }})
                this.setState({ imageName: data.image_name })
                {this.setState({isModalVisible: true ,setEditId:id})}
            })
            .catch(err => {})
    }

    render() {
        return (
            <>
            <div>
                <div className='process_buttons'>
                    <Button style={{ display:'inline-block'}} type="primary" className="mb-3 ml-3"  onClick={() => this.showModal()} >Add New Frame</Button>
                </div>
                <TableComp loading={this.props.loading} columns={this.columns} dataSource={this.state.source} />
                {(this.state.otherSource.page) && (
                <div className="pagination-container">
                    <Pagination current={this.state.otherSource.page} showSizeChanger={false} onChange={this.handlepagination} pageSize={100} defaultPageSize={100} total={this.state.otherSource.total} />
                </div>
                )}
                <Modal className="list-user-editmodal" centered visible={this.state.isModalVisible} onCancel={()=> this.handleCancel()} closable footer={null} >
                    <div className='list-user-editmodal-inner'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="box">
                                        <Form name="nest-messages" layout="horizontal" onFinish={this.onFinish}>
                                            <h5>Add Frame</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <Form.Item name="image_url" label="Upload collage Frame" rules= {!this.state.preview.frameFile ? [{required:true, message:"This field is required"}] :""}>
                                                    <div className="d-flex">
                                                        <Upload accept={'.png'} listType="picture" maxCount={1} customRequest={this.customRequestFun}>
                                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                        </Upload>
                                                        <div className="ml-3">
                                                            <Image
                                                                width={100}
                                                                src={this.state.preview.frameFile}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <Form.Item initialValue={this.state.imageName} name="image_name" label="Name" rules= {[{ required: true, message: 'This field is required!'}]}>
                                                        <Input type="text" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <Form.Item>
                                                <div className="d-flex">
                                                    <Button type="primary" loading={this.state.imageLoading} htmlType="submit">
                                                        Upload
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            </>
            
        )
    }
}

export default withRouter(CollageFrameTableColumn)
