import axios from 'axios'
import React, { useState } from 'react'

import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Upload, Modal, Spin, Button } from 'antd';
import { uploadImages } from '../configurations/urls';
import { Store } from 'react-notifications-component';

const { Dragger } = Upload;

const BulkImageUpload = () => {
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [fileList, setFileList] = useState([]);
    const [imageLoading, setImageLoading] = useState(false);
  
    const handleCancel = () => setPreviewVisible(false);

    const getBase64 = (file)=>{
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
  
    const handlePreview =async (file)=> {
        setPreviewImage(await getBase64(file.originFileObj));
        setPreviewVisible(true)
    };
  
    const handleUpload = (fileList) => {
        setFileList((fileList.fileList));
    };
  
    const handleSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();
        for( let i = 0; i< fileList.length ; i++){
            formData.append('image', fileList[i].originFileObj);
        }
        setImageLoading(true)
        axios.post(uploadImages, formData)
            .then(res => {
                setImageLoading(false)
                Store.addNotification({
                    title: res.data.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3500,
                      onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.reload();
                }, 3500)
            })
            .catch(err => {
                setImageLoading(false)
                Store.addNotification({
                    title: err.response.data.message,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 3500,
                      onScreen: true
                    }
                });
                setTimeout(() => {
                    window.location.reload();
                }, 3500)
            });
    };
    return (
        <>
        { !!imageLoading &&
            <div className='loader'>
                <Spin />
                <h3 className="ml-3 text-white" >Uploading Images Please Wait...</h3>
            </div>
        }
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="manual">
                        <Dragger
                            multiple={true}
                            accept={'.jpg, .jpeg'}
                            listType="picture"
                            onPreview={handlePreview}
                            onChange={handleUpload}
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </Dragger>
                        {fileList.length > 0 ?
                            <Button type="primary" className="mt-3 mb-3" icon={<UploadOutlined />} onClick={handleSubmit}>
                                Upload
                            </Button>
                        :
                            <Button type="primary" className="mt-3 mb-3" icon={<UploadOutlined />} disabled>
                                Upload
                            </Button>
                        }
                        <Modal
                            visible={previewVisible}
                            footer={null}
                            centered
                            onCancel={handleCancel}
                            className='multiBtn_modal'
                        >
                            <img alt="example" style={{ width: "100%" }} src={previewImage} />
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}
  
  
  
  export default BulkImageUpload