import { CloseOutlined, LeftCircleFilled, RightCircleFilled, UploadOutlined } from '@ant-design/icons'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { Spin, Upload, Button, Image } from 'antd';
import { useDispatch } from 'react-redux';
import { sendToEditor } from './../Actions/index';
import { withRouter } from 'react-router-dom';
import { getImageSlider, saveProcessedImage } from './../configurations/urls';
import { triggerBase64Download } from 'react-base64-downloader';

const ImageViewer = ({ current, changeFun, onClose, history,link,setUpdateData,updateImage,setUpdateImage }) => {
    const [loading, setLoading] = useState(false)
    const [pending,setPending]=useState('')
    const dispatch = useDispatch()

    useEffect(()=>{
        document.addEventListener('keydown', function(event){
            if(event.keyCode===39){
                changeFun('next')
            }else if(event.keyCode===37){
                changeFun('prev')
            }
        
        })
    },[])

    const openEditor = async (a) => {

        let image = await axios.get(getImageSlider+a.image_id+"/");
        // let orignal = Buffer.from(image.data).toString('base64');
        let orignal = image.data['original_image'];
        // image = await axios.get(`${a.processed_image}`, { responseType: 'arraybuffer' })
        // let processed = Buffer.from(image.data).toString('base64')
        // const { access, refresh } = JSON.parse(localStorage.getItem('userCreds'))
        // axios.defaults.headers.common['Authorization'] = `JWT ${access}`;
        let processed = image.data['processed_image'];
        var doc = document.documentElement;
        const currentPosition = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
        window.localStorage.setItem('inventory-list-top', currentPosition)
        window.localStorage.setItem("current_image_id", image.data['image_order']);
        window.localStorage.setItem("current_csv_data_id", image.data['csv_data_id']);
        dispatch(
            sendToEditor({
              orignalImage: `${orignal}`,
              removedImage: `${processed}`,
              link: link,
              imgName:
                a.editted_image.split("/")[a.editted_image.split("/").length - 1],
              dealerId: a.owner,
              original_image: a.original_image,
              images_edited: a.images_edited,
              image_id: a.image_id,
               image_updated: a.image_updated,
               csv_id: image.data['csv_data_id'],
               current_id: image.data['image_order'],
               original_url: a.link,
               stock_number: a.stock_number
            })
          );
          localStorage.setItem("pervious_route" , link)
        history.push('/editor')

    }
    const openEdit = (data) => {
        openEditor(data)
        setLoading(true)
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const uploadImage = async(options) => {
        setPending('upload')
        const {file}=options
        let b64 = await toBase64(file)
        // b64 = b64.replace('data:image/png;base64,', '')
        b64 = b64.replace(/^data:image\/[a-z]+;base64,/, "");
        let imagedata = current.data.processed_image.split("/")[current.data.processed_image.split("/").length - 1]
        imagedata= imagedata.split("?")[0]
        axios.post(`${saveProcessedImage}${current.data.image_id}/${current.data.owner}/`, {
            image_data:b64,
            image_id: current.data.image_id,
            images_edited: current.data.images_edited,
            link: current.data.original_image,})
            .then(res => {
                setUpdateImage(current.img)
                setUpdateData()
                setPending('')
                onClose()
               history.push(link)
            })
            .catch(err => {
              
                setPending('')
            })
    }
    function toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            var reader = new FileReader();
            reader.onloadend = function() {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }
    const download=()=>{
        setPending('download')
     
        toDataUrl(current.data.processed_image, function(myBase64) {
            triggerBase64Download(myBase64, 'background-removal-edit');
            setPending('')
        });
        
    }
    return (
        <div className="img-viewer">
            <div className="overlay"></div>
            {loading ? <div className="container">
                <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
                    <Spin />
                    <h3 className="ml-3 text-white" style={{ zIndex: '222' }}>Opening Editor please wait...</h3>
                </div>

            </div> : 
                <>
                    <div className="slider-section">
                        <div className="body">
                            <div className="close" onClick={onClose}>
                                <CloseOutlined />
                            </div>

                            <div className="arrows arrow-left" onClick={() => changeFun('prev')}>
                                <LeftCircleFilled />
                            </div>
                            <div className="topbar">
                                <div className="title">DealerId: {current.data.owner && current.data.owner}</div>
                            </div>

                            <div className='slider-content'>
                                <div className='slider-single-img no-bot-slider'>
                                    <img src={updateImage===current.img?`${current.img}?${Date.now()}`:current.img} width="500" />
                                </div>
                            </div>

                            <div className="arrows arrow-right" onClick={() => changeFun('next')}>
                                <RightCircleFilled />
                            </div>
                            {
                                current.type === 'rem' && 
                                <div className="d-flex bottombar">
                                    <button className="btm btn-sm btn-primary" onClick={() => openEdit(current.data)}>Edit</button>
                                    <button className="btm btn-sm btn-success mx-3" disabled={pending==='download'} onClick={download}>Download</button>
                                    <Upload
                                        customRequest={uploadImage}
                                        maxCount={1}
                                        showUploadList={false}
                                    >
                                        <Button loading={pending==='upload'} icon={<UploadOutlined />}>Replace</Button>
                                    </Upload>
                                </div>
                            }
                        </div>
                    </div>

                </>}
        </div>
    )
}

export default withRouter(ImageViewer)
