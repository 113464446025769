import React, { useState, useEffect } from 'react'
import { Form, Input, InputNumber, Button, Upload, Image, Modal ,Checkbox } from 'antd';
import { UploadOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios'
import { adminLink, baseURLWithoutSlash} from '../configurations/urls';
import { Radio } from 'antd';
import Select from 'react-select/creatable';

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const AdminSettings = ({ active, id }) => {
    const [preview, setPreview] = useState({ newfile: '', usedfile: '', AddFile: '' })
    const [stateFile, setStateFile] = useState({ newfile: '', usedfile: '', AddFile: '' })
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [isSecondVehicle, setIsSecondVehicles] = useState(false)
    const [visibleDealerId, setVisibleDealer] = useState(false);
    const [visibleVinField , setVisibleVinField] = useState(false)
    const [visibleLotLocation , setVisibleLotLocation] = useState(false)
    const [visibleVehicleType , setVisibleVehicalType] = useState(false)
    const [visibleImages , setVisibleImage] = useState(false)
    const [othersValue , setOthersValue] = useState([])
    const [visibleStockNumber , setVisibleStockNumber] = useState(false)
    const [visibleLastLotService , setVisibleLastLotService] = useState(false)
    const [otherFields, setOtherFields] = useState("lot_loc_2")
    const [otherCsvFormat, setOtherCsvFormat] = useState("current_name")
    const [newCustomOptions, setNewCustomOptions] = useState([])
    const [secondCustomOptions, setSecondCustomOptions] = useState([])
    const [usedCustomOptions, setUsedCustomOptions] = useState([])
    const [newCustomOrder , setNewCustomOrder] = useState([])
    const [secondCustomOrder , setSecondCustomOrder] = useState([])
    const [usedCustomOrder , setUsedCustomOrder] = useState([])
    const [newCommonOptions, setNewCommonOptions] = useState("first_last")
    const [secondCommonOptions, setSecondCommonOptions] = useState("first_last")
    const [usedCommonOptions, setUsedCommonOptions] = useState("first_last")
    const [qrValue, setQRValue] = useState(false)
    
    useEffect(() => {
        let arr = [];
        for(let i=1; i<=100; i++){
            arr.push({value: i.toString(), label:i.toString()})
        }
        setNewCustomOptions(arr)
        setSecondCustomOptions(arr)
        setUsedCustomOptions(arr)
    }, [])
    useEffect(() => {
        if (id) {
            form.setFieldsValue({ user: "" })
            setStateFile({
                newfile: "",
                usedfile: "",
                AddFile: ""
            })
        
            axios.get(`${adminLink}${id}/`)
                .then(async res => {
                    let data = { ...res.data, ...res.data.user_preferences }
                   
                    setIsSecondVehicles(data.second_lot_status)
                  let othervalue =  !!data.others ? data.others.split(",").map((data) => { return { label: data, value: data } }) : []
                    setOthersValue(othervalue)
                    let newOrder =  !!data.new_custom_order ? data.new_custom_order.split(",").map((data) => { return { label: data, value: data } }) : []
                    setNewCustomOrder(newOrder)
                    let secondOrder =  !!data.second_custom_order ? data.second_custom_order.split(",").map((data) => { return { label: data, value: data } }) : []
                    setSecondCustomOrder(secondOrder)
                    let usedOrder =  !!data.used_custom_order ? data.used_custom_order.split(",").map((data) => { return { label: data, value: data } }) : []
                    setUsedCustomOrder(usedOrder)
                    data.vin =  (!!data  && data.vin && data.vin != "undefined" )?data.vin:"Vin"
                    data.dealerid =  (!!data  && data.dealerid && data.dealerid != "undefined" )?data.dealerid:"Dealer Id"
                    data.lot_location =  (!!data  && data.lot_location && data.lot_location != "undefined" )?data.lot_location:"Lot Location"
                    data.vehicle_type =  (!!data  && data.vehicle_type && data.vehicle_type != "undefined" )?data.vehicle_type:"Vehicle Type"
                    data.images =  (!!data  && data.images && data.images != "undefined" )?data.images:"Images"
                    data.stock_number =  (!!data  && data.stock_number && data.stock_number != "undefined" )?data.stock_number:"StockNumber"
                    data.last_lot_service =  (!!data  && data.last_lot_service && data.last_lot_service != "undefined" )?data.last_lot_service:"LastLotService"
                    data.lot_location_value =  (!!data  && data.lot_location_value && data.lot_location_value != "undefined" )?data.lot_location_value:"lot2"
                    data.used_common_options = (!!data  && data.used_common_options && data.used_common_options != "undefined" )?data.used_common_options:"first_last"
                    data.second_common_options = (!!data  && data.second_common_options && data.second_common_options != "undefined" )?data.second_common_options:"first_last"
                    data.new_common_options = (!!data  && data.new_common_options && data.new_common_options != "undefined" )?data.new_common_options:"first_last"
                    data.use_new_common_options = (!!data  && data.use_new_common_options && data.use_new_common_options != "undefined" )?data.use_new_common_options: false
                    data.use_second_common_options = (!!data  && data.use_second_common_options && data.use_second_common_options != "undefined" )?data.use_second_common_options:false 
                    data.use_used_common_options= (!!data  && data.use_used_common_options && data.use_used_common_options != "undefined" )?data.use_used_common_options:false
                    data.background_option = (!!data  && data.background_option && data.background_option != "undefined" )?data.background_option:"lot_loc_2"
                    data.csv_name_option = (!!data  && data.csv_name_option && data.csv_name_option != "undefined" )?data.csv_name_option:"current_name"
                    data.image_update_column= (!!data  && data.image_update_column && data.image_update_column != "undefined" )?data.image_update_column:false
                    data.use_update_date= (!!data  && data.use_update_date && data.use_update_date != "undefined" )?data.use_update_date:false
                    data.generate_qr_code= (!!data  && data.generate_qr_code && data.generate_qr_code != "undefined" )?data.generate_qr_code:false
                    data.qr_code_field =  (!!data  && data.qr_code_field && data.qr_code_field != "undefined" )?data.qr_code_field:""
                    data.remove_empty_service_date= (!!data  && data.remove_empty_service_date && data.remove_empty_service_date != "undefined" )?data.remove_empty_service_date:false
                    data.use_redline_service= (!!data  && data.use_redline_service && data.use_redline_service != "undefined" )?data.use_redline_service:false
                    data.update_all_url= (!!data  && data.update_all_url && data.update_all_url != "undefined" )?data.update_all_url:false
                    data.send_all_updated_url= (!!data  && data.send_all_updated_url && data.send_all_updated_url != "undefined" )?data.send_all_updated_url:false
                    data.send_images_count= (!!data  && data.send_images_count && data.send_images_count != "undefined" )?data.send_images_count:false
                    setImageStates(data)
                    setOtherFields(data.background_option)
                    setOtherCsvFormat(data.csv_name_option)
                    setNewCommonOptions(data.new_common_options)
                    setSecondCommonOptions(data.second_common_options)
                    setUsedCommonOptions(data.used_common_options)
                    setQRValue(data.generate_qr_code)
                })
                .catch(err => {})
        }

    }, [id])

    const setImageStates = async (data) => {
        form.setFieldsValue({ user: data })
        setPreview({
            newfile: !!data.new_background_image?`${baseURLWithoutSlash}${data.new_background_image}`:"",
            usedfile: !!data.used_background_image?`${baseURLWithoutSlash}${data.used_background_image}`:"",
            AddFile: !!data.second_background_image?`${baseURLWithoutSlash}${data.second_background_image}`:""
        })

    }

    const onFinish = (values) => {
        
        const other = othersValue.map((data) => data.value)
        const newOrder = newCustomOrder.map((data) => data.value)
        const secondOrder = secondCustomOrder.map((data) => data.value)
        const usedOrder = usedCustomOrder.map((data) => data.value)
      
        setError(null)
        setLoading(true)
        values.user.new_enhance_all = true
        values.user.used_enhance_all = true
        const formData = new FormData()
        for (var key in values.user) {
            var value = values.user[key];
            formData.append(key, value)
        }
     
      formData.append('dealerid',form.getFieldValue().user.dealerid)
      formData.append('vin',form.getFieldValue().user.vin)
      formData.append('lot_location',form.getFieldValue().user.lot_location)
      formData.append('vehicle_type',form.getFieldValue().user.vehicle_type)
      formData.append('images',form.getFieldValue().user.images)
      formData.append('lot_location_value',form.getFieldValue().user.lot_location_value)
      formData.append('others',other.join(","))
      formData.append('new_background_image', stateFile.newfile)
      formData.append('used_background_image', stateFile.usedfile)
      formData.append('new_custom_order',newOrder.join(","))
      formData.append('used_custom_order',usedOrder.join(","))
      

        if (!!isSecondVehicle) {
            formData.append('second_background_image', stateFile.AddFile)
            formData.append('second_custom_order',secondOrder.join(","))
        }
        formData.append('second_lot_status', !!isSecondVehicle && isSecondVehicle !="undefined"? true : false)
        formData.append('user_added', true)
        let put
        if (id) {
            put = axios.put(`${adminLink}${id}/`, formData)
        }

        put.then(res => {
            setSuccess(true)
            setLoading(false)
            setTimeout(() => {
                setSuccess(null)
            }, 2000)


        })
            .catch(err => {
                setLoading(false)
                if (err.response)
                    setError(err.response.data.message)
                else
                    setError('There is something wrong')
            })
    };

    const customRequestFun = (options) => {
        const { onSuccess, file } = options;
        setStateFile({ ...stateFile, newfile: file })
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function () {
            setPreview({ ...preview, newfile: reader.result })
        };
        onSuccess(file)
    };
    const AddCustomRequestFun = (options) => {
        const { onSuccess, file } = options;
        setStateFile({ ...stateFile, AddFile: file })
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function () {
            setPreview({ ...preview, AddFile: reader.result })
        };
        onSuccess(file)
    };
    const customRequestFun1 = (options) => {
        const { onSuccess, file } = options;
        setStateFile({ ...stateFile, usedfile: file })
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = function () {
            setPreview({ ...preview, usedfile: reader.result })
        };
        onSuccess(file)
    };
    const inputConfig = {
        rules: [
            {
                required: true,
                message: 'This field is required!',
            },
        ],
    };
    const dateConfig = {
        rules: [
            {
                required: true,
                message: 'This field is required!',
            },
        ],
    };

    const uploadConfig = {
        rules: [
            {
                required: true,
                message: 'This field is required!',
            },
        ],
    };
    const OpenNewVehicals = () => {

        if (!!isSecondVehicle) {
            setIsSecondVehicles(false)
        }
        else {
            setIsSecondVehicles(true)
        }

    }
    const handleOptionChange = (e) => {
        setOtherFields(e.target.value)
    };
    const handleFormatChange = (e) => {
        setOtherCsvFormat(e.target.value)
    };
    const handlenewcommonChange = (e) => {
        setNewCommonOptions(e.target.value)
    };
    const handlesecondcommonChange = (e) => {
        setSecondCommonOptions(e.target.value)
    };
    const handleusedcommonChange = (e) => {
        setUsedCommonOptions(e.target.value)
    };
    const handleQRChange = (e) => {
        setQRValue(e.target.checked)
    };

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="box">
                            <Form name="nest-messages" form={form} layout="horizontal" onFinish={onFinish} validateMessages={validateMessages}>
                                <h5>Dealer Information</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'dealer_name']} label="Dealer Name">
                                            <Input disabled />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'address']} label="Address">
                                            <Input disabled />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email' }]}>
                                            <Input disabled />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'city']} label="City">
                                            <Input disabled />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'phone']} label="Phone">
                                            <Input disabled />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'state']} label="State">
                                            <Input disabled />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'website']} label="Website">
                                            <Input disabled />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'zip_code']} label="Zip">
                                            <Input disabled />
                                        </Form.Item>
                                    </div>


                                </div>

                                <h5>FTP Information</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'host']} {...inputConfig} label="Host"  >
                                            <Input type="text" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'port']} {...inputConfig} label="Port" >
                                            <Input type="text" />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'username']} {...inputConfig} label="Username" >
                                            <Input type="text" placeholder="Username" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'password']} {...inputConfig} label="Password"  >
                                            <Input type="password" placeholder="Password" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <h5 className="mt-4">Exterior Background</h5>
                                <h6>New Vehicles</h6>
                                <Form.Item name={['user', 'new_num_images']} {...inputConfig} label="Num images to enchance per vehicle">
                                    <Input />
                                </Form.Item>
                                <Form.Item name={['user', 'use_new_common_options']} valuePropName="checked" label="Use common options">
                                <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item name={['user', 'new_common_options']} label="Common options ">
                                <Radio.Group onChange={handlenewcommonChange}>
                                    <Radio value={"first_last"}>First and last</Radio>
                                    <Radio value={"first_second_last"}>First and second to last</Radio>
                                    <Radio value={"first_last_three"}>First and second, third, fourth to last</Radio>
                                    <Radio value={"custom_order"}>Custom order</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {newCommonOptions == "custom_order" &&
                                <div className='mb-4 other_multiselect'>
                                <Select
                                placeholder="Select order"
                                value={newCustomOrder}
                                options={newCustomOptions}
                                formatCreateLabel={() => undefined}
                                isValidNewOption={() => false}
                                isMulti
                                onChange={(data) => setNewCustomOrder(data)} />
                                <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                value={newCustomOrder}
                                required={true}
                                />
                                </div>
                                }
                                <Form.Item name={['user', 'new_only_enhance_min_num']} {...inputConfig} label="Only enhance if vehicle has a minimun of" >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name={['user', 'new_background_image']} label="Upload background image" rules={!preview.newfile ? [{required:true, message:"This field is required"}] :""}>
                                    <div className="d-flex">
                                        <Upload listType="picture" maxCount={1} customRequest={customRequestFun}>
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                        <div className="ml-3">
                                            <Image
                                                width={80}
                                                src={preview.newfile}
                                            />
                                        </div>

                                    </div>

                                </Form.Item>
                                {!!isSecondVehicle ?
                                    <Button className='redClass' icon={<CloseOutlined />} onClick={() => OpenNewVehicals()}>No Need To Add New Vehicles</Button> :
                                    <Button icon={<PlusOutlined />} onClick={() => OpenNewVehicals()}>Add New Vehicles</Button>}

                                {!!isSecondVehicle &&
                                    <>
                                <Form.Item name={['user', 'background_option']} label="Background options">
                                <Radio.Group onChange={handleOptionChange} value={otherFields}>
                                    <Radio value={"lot_loc_2"}>Lot Location</Radio>
                                    <Radio value={"other_csv_field"}>Other field</Radio>
                                </Radio.Group>
                                </Form.Item>
                                {otherFields == "other_csv_field" &&
                                <>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <Form.Item name={['user', 'other_csv_option']} {...inputConfig} label="Field name">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Item name={['user', 'other_csv_value']} {...inputConfig} label="Field value">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                                </>
                                }
                                        <Form.Item name={['user', 'second_num_images']} {...inputConfig} label="Num images to enchance per vehicle">
                                            <Input />
                                        </Form.Item>
                                        <Form.Item name={['user', 'use_second_common_options']} valuePropName="checked" label="Use common options">
                                <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item name={['user', 'second_common_options']} label="Common options ">
                                <Radio.Group  onChange={handlesecondcommonChange}>
                                    <Radio value={"first_last"}>First and last</Radio>
                                    <Radio value={"first_second_last"}>First and second to last</Radio>
                                    <Radio value={"first_last_three"}>First and second, third, fourth to last</Radio>
                                    <Radio value={"custom_order"}>Custom order</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {secondCommonOptions == "custom_order" &&
                                <div className='mb-4 other_multiselect'>
                                <Select
                                placeholder="Select order"
                                value={secondCustomOrder}
                                options={secondCustomOptions}
                                formatCreateLabel={() => undefined}
                                isValidNewOption={() => false}
                                isMulti
                                onChange={(data) => setSecondCustomOrder(data)} />
                                <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                value={secondCustomOrder}
                                required={true}
                                />
                                </div>
                                }
                                        <Form.Item name={['user', 'second_only_enhance_min_num']} {...inputConfig} label="Only enhance if vehicle has a minimun of" >
                                            <InputNumber />
                                        </Form.Item>
                                        <Form.Item name={['user', 'second_background_image']}  rules={!preview.AddFile ? [{required:true, message:"This field is required"}] :""} label="Upload background image">
                                            <div className="d-flex">
                                                <Upload listType="picture" maxCount={1} customRequest={AddCustomRequestFun}>
                                                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                </Upload>
                                                <div className="ml-3">
                                                    <Image
                                                        width={80}
                                                        src={preview.AddFile}
                                                    />
                                                </div>

                                            </div>

                                        </Form.Item>
                                    </>
                                }
                                <h6 className="pt-4">Used Vehicles</h6>
                                <Form.Item name={['user', 'used_num_images']} {...inputConfig} label="Num images to enchance per vehicle">
                                    <Input />
                                </Form.Item>
                                <Form.Item name={['user', 'use_used_common_options']} valuePropName="checked" label="Use common options">
                                <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item name={['user', 'used_common_options']} label="Common options ">
                                <Radio.Group onChange={handleusedcommonChange}>
                                    <Radio value={"first_last"}>First and last</Radio>
                                    <Radio value={"first_second_last"}>First and second to last</Radio>
                                    <Radio value={"first_last_three"}>First and second, third, fourth to last</Radio>
                                    <Radio value={"custom_order"}>Custom order</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {usedCommonOptions == "custom_order" &&
                                <div className='mb-4 other_multiselect'>
                                <Select
                                placeholder="Select order"
                                value={usedCustomOrder}
                                options={usedCustomOptions}
                                formatCreateLabel={() => undefined}
                                isValidNewOption={() => false}
                                isMulti 
                                onChange={(data) => setUsedCustomOrder(data)}/>
                                <input
                                tabIndex={-1}
                                autoComplete="off"
                                style={{ opacity: 0, height: 0 }}
                                value={usedCustomOrder}
                                required={true}
                                />
                                </div>
                                }
                                <Form.Item name={['user', 'used_only_enhance_min_num']} {...inputConfig} label="Only enhance if vehicle has a minimun of" >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name={['user', 'used_background_image']} rules={!preview.usedfile ? [{required:true, message:"This field is required"}] :""} label="Upload background image">
                                    <div className="d-flex">
                                        <Upload listType="picture"  maxCount={1} customRequest={customRequestFun1}>
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                        <div className="ml-3">
                                            <Image
                                                width={80}
                                                src={preview.usedfile}
                                            />
                                        </div>
                                    </div>


                                </Form.Item>
                                <h6 className="pt-4">Notes/Comments:</h6>
                                <Form.Item name={['user', 'notes']} >
                                    <Input.TextArea />
                                </Form.Item>
                                <h6 className="pt-4">QR Code</h6>
                                <Form.Item name={['user', 'generate_qr_code']} onChange={handleQRChange} valuePropName="checked" label="Generate QR code ">
                                <Checkbox></Checkbox>
                                </Form.Item>
                                {!!qrValue &&
                                <>
                                <Form.Item name={['user', 'qr_code_field']} {...inputConfig} label="QR link field name">
                                            <Input  type="text" />
                                        </Form.Item>
                                        </>
                                }
                                <Form.Item name={['user', 'lot_location_value']} label="Lot Location2 value"  >
                                    <Input type="text" />
                                </Form.Item>
                                <Form.Item name={['user', 'csv_name_option']} label="CSV name format">
                                <Radio.Group onChange={handleFormatChange} value={otherCsvFormat}>
                                    <Radio value={"current_name"}>Current name</Radio>
                                    <Radio value={"other_name"}>Other name</Radio>
                                </Radio.Group>
                                </Form.Item>
                                {otherCsvFormat == "other_name" &&
                                <>
                                        <Form.Item name={['user', 'other_csv_format']} {...inputConfig} label="CSV file name">
                                            <Input  type="text" />
                                        </Form.Item>
                                </>
                                }
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'image_update_column']} valuePropName="checked" label="Add image update column">
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'use_update_date']} valuePropName="checked" label="Add date in image update column">
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'remove_empty_service_date']} valuePropName="checked" label="Only enhance if Last Lot Service date">
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'use_redline_service']} valuePropName="checked" label="Dealer uses Redline service">
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'update_all_url']} valuePropName="checked" label="Update all image URLs for record">
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                    <Form.Item name={['user', 'send_all_updated_url']} valuePropName="checked" label="Send all updated URLs in CSV">
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                    <Form.Item name={['user', 'send_images_count']} valuePropName="checked" label="Send updated images count">
                                            <Checkbox></Checkbox>
                                        </Form.Item>
                                    </div>
                                </div>
                               
                      
                                <h5 className="mt-4">Manage CSV fields</h5>
                                <div className='multiButtonWrap'>
                                    <Button type="primary" onClick={() => setVisibleDealer(true)}>
                                        {(!!form.getFieldValue() && !!form.getFieldValue().user &&!!form.getFieldValue().user.dealerid && form.getFieldValue().user.dealerid != "undefined") ? form.getFieldValue().user.dealerid:"Dealer Id"}
                                    </Button>
                                    <Button type="primary" onClick={() => setVisibleVinField(true)}>
                               {(!!form.getFieldValue() && !!form.getFieldValue().user && form.getFieldValue().user.vin && form.getFieldValue().user.vin != "undefined" )?form.getFieldValue().user.vin:"Vin"}
                                </Button>
                                <Button type="primary" onClick={() => setVisibleLotLocation(true)}>
                               {(!!form.getFieldValue() && !!form.getFieldValue().user && !!form.getFieldValue().user.lot_location && form.getFieldValue().user.lot_location != "undefined") ?form.getFieldValue().user.lot_location:"Lot Location"}
                                </Button>
                                <Button type="primary" onClick={() => setVisibleVehicalType(true)}>
                               {(!!form.getFieldValue() && !!form.getFieldValue().user && form.getFieldValue().user.vehicle_type && form.getFieldValue().user.vehicle_type!= "undefined") ?form.getFieldValue().user.vehicle_type:"Vehicle Type"}
                                </Button>
                                <Button type="primary" onClick={() => setVisibleImage(true)}>
                               {(!!form.getFieldValue() && !!form.getFieldValue().user && form.getFieldValue().user.images && form.getFieldValue().user.images != "undefined") ?form.getFieldValue().user.images:"Images"}
                                </Button>
                                <Button type="primary" onClick={() => setVisibleStockNumber(true)}>
                               {(!!form.getFieldValue() && !!form.getFieldValue().user && form.getFieldValue().user.stock_number && form.getFieldValue().user.stock_number != "undefined") ?form.getFieldValue().user.stock_number:"StockNumber"}
                                </Button>
                                <Button type="primary" onClick={() => setVisibleLastLotService(true)}>
                               {(!!form.getFieldValue() && !!form.getFieldValue().user && form.getFieldValue().user.last_lot_service && form.getFieldValue().user.last_lot_service != "undefined") ?form.getFieldValue().user.last_lot_service:"LastLotService"}
                                </Button>
                                </div>

                                <Modal title={<h5>Update Dealer Id</h5>} className='multiBtn_modal' closable={true} centered footer={null} onCancel={() => !!form.getFieldValue() && form.getFieldValue().user.dealerid ?setVisibleDealer(false) :"" } visible={visibleDealerId} >
                                    <Form.Item name={['user', 'dealerid']} {...inputConfig} label="Dealer Id">
                                        <Input   />
                                    </Form.Item>
                                    <div className='multiBtn_modal-btnwrap'>
                                    <button onClick={()=> !!form.getFieldValue() && form.getFieldValue().user.dealerid && form.getFieldValue().user.dealerid !="undefined" ?setVisibleDealer(false) :""}>Update</button>
                                    </div>
                                </Modal>
                                

                                <Modal title={<h5>Update VIN</h5>} className='multiBtn_modal' closable={true} centered footer={null} onCancel={() => !!form.getFieldValue() && form.getFieldValue().user.vin ? setVisibleVinField(false):""} visible={visibleVinField} >
                                <Form.Item name={['user', 'vin']} {...inputConfig} label="VIN">
                                         <Input />
                                     </Form.Item>
                                     <div className='multiBtn_modal-btnwrap'>
                                     <button onClick={()=> !!form.getFieldValue() && form.getFieldValue().user.vin && form.getFieldValue().user.vin !="undefined" ?setVisibleVinField(false) :""}>Update</button>
                               </div>
                                </Modal>

                                <Modal title={<h5>Update Lot Location</h5>} className='multiBtn_modal' closable={true} centered footer={null} onCancel={() => !!form.getFieldValue() && form.getFieldValue().user.lot_location ? setVisibleLotLocation(false):""} visible={visibleLotLocation} >
                                <Form.Item name={['user', 'lot_location']} {...inputConfig} label="Lot Location">
                                    <Input />
                                </Form.Item>
                                <div className='multiBtn_modal-btnwrap'>
                                <button onClick={()=> !!form.getFieldValue() && form.getFieldValue().user.lot_location && form.getFieldValue().user.lot_location !="undefined" ?setVisibleLotLocation(false) :""}>Update</button>
                              </div>
                                </Modal>

                                <Modal title={<h5>Update Vehicle Type</h5>} className='multiBtn_modal' closable={true} centered footer={null} onCancel={() => !!form.getFieldValue() && form.getFieldValue().user.vehicle_type ?setVisibleVehicalType(false) :"" } visible={visibleVehicleType} >
                                <Form.Item name={['user', 'vehicle_type']} {...inputConfig} label="Vehicle Type">
                                    <Input />
                                </Form.Item>
                                <div className='multiBtn_modal-btnwrap'>
                                <button onClick={()=> !!form.getFieldValue() && form.getFieldValue().user.vehicle_type && form.getFieldValue().user.vehicle_type !="undefined" ?setVisibleVehicalType(false) :""}>Update</button>
                                </div>
                                </Modal>

                                <Modal title={<h5>Update Images</h5>} className='multiBtn_modal' closable={true} centered footer={null} onCancel={() => !!form.getFieldValue() && form.getFieldValue().user.images ?setVisibleImage(false) :"" } visible={visibleImages} >
                                <Form.Item name={['user', 'images']} {...inputConfig} label="Images">
                                    <Input />
                                </Form.Item>
                                <div className='multiBtn_modal-btnwrap'>
                                <button onClick={()=> !!form.getFieldValue() && form.getFieldValue().user.images && form.getFieldValue().user.images !="undefined" ?setVisibleImage(false) :""}>Update</button>
                                </div>
                                </Modal>

                                <Modal title={<h5>Update Stock Number</h5>} className='multiBtn_modal' closable={true} centered footer={null} onCancel={() => !!form.getFieldValue() && form.getFieldValue().user.stock_number ?setVisibleStockNumber(false) :"" } visible={visibleStockNumber} >
                                <Form.Item name={['user', 'stock_number']} {...inputConfig} label="Stock Number">
                                    <Input />
                                </Form.Item>
                                <div className='multiBtn_modal-btnwrap'>
                                <button onClick={()=> !!form.getFieldValue() && form.getFieldValue().user.stock_number && form.getFieldValue().user.stock_number !="undefined" ?setVisibleStockNumber(false) :""}>Update</button>
                                </div>
                                </Modal>

                                <Modal title={<h5>Update Last Lot Service</h5>} className='multiBtn_modal' closable={true} centered footer={null} onCancel={() => !!form.getFieldValue() && form.getFieldValue().user.last_lot_service ?setVisibleLastLotService(false) :"" } visible={visibleLastLotService} >
                                <Form.Item name={['user', 'last_lot_service']} {...inputConfig} label="Last Lot Service">
                                    <Input />
                                </Form.Item>
                                <div className='multiBtn_modal-btnwrap'>
                                <button onClick={()=> !!form.getFieldValue() && form.getFieldValue().user.last_lot_service && form.getFieldValue().user.last_lot_service !="undefined" ?setVisibleLastLotService(false) :""}>Update</button>
                                </div>
                                </Modal>
                             
                                <h6 className="pt-4">Other fields</h6>
                                <div className='other_multiselect'>
                                <Select
                                className='other_multiselect-select'
                                placeholder="Other fields"
                                value={othersValue}
                                isMulti={true}
                                onChange={(data) => setOthersValue(data)} />
                                </div>
                                
                                <Form.Item>
                                    <div className="d-flex">
                                        <Button type="primary" loading={loading} htmlType="submit">
                                            Update Info
                                        </Button>
                                        {error && <p className="text-danger ml-3">
                                            {error}
                                        </p>}
                                        {success && <p className="text-success ml-3">
                                            Information Successfully Updated
                                        </p>}
                                    </div>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(AdminSettings)
