import React, { useState } from 'react'
import { Table } from 'antd'

const TableComp = ({ dataSource, columns,loading,scrollBar=true }) => {

    const [currentPage, setCurrentPage] = useState()

    return (
        <div>
            {scrollBar ?
            <Table dataSource={dataSource} columns={columns} 
            rowClassName={(record, index) => 
                (record.recently_added  && "recentadd-record") || (record.expired_overlay && "expired-overlay" || record.import_completed && "import-completed") } 
            scroll={{ x: 220 }} 
            pagination={false}
            // pagination={{
            //     current: 1,
            //     pageSize: 50,
            //   }} 
              loading={loading} />:
            <Table dataSource={dataSource} columns={columns} scroll={{y:500}} loading={loading} 
            pagination={false}
            // pagination={{
            //     current: 1,
            //     pageSize: 300,
            //   }} 
               />}
        </div>
    )
}

export default TableComp
