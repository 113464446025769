import React from 'react'
import TableComp from './TableComp'
import { Input, Button, Space, Popconfirm ,Modal, Tag, Switch} from 'antd'
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words';
import { SearchOutlined ,EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { managerLink, deactivateUser } from '../configurations/urls';
import { Pagination } from 'antd'
import ClientSetting from './ClientSetting';
import { Store } from 'react-notifications-component';
const { confirm } = Modal;

class ManagerTableColumn extends React.Component {

    state = {
        searchText: '',
        isModalVisible:false,
        setEditId:"",
        searchedColumn: '',
        isModalVisible: false,
        visible: false,
        index: 0,
        source: '',
        otherSource: 1,
        sorceData: this.props.dataSource,
        setdealers: [],
        userType : ''
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90, alignItems: 'center', display: 'inline-block' }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    activateDeactivateUser = (checked, id) => {
        let modal_txt = "Are you sure you want to deactivate this user ?";
        if (checked){
            modal_txt = "Are you sure you want to activate this user ?"
        }
        confirm ({ 
            icon: <ExclamationCircleOutlined />,
            content: modal_txt,
            className: "confirm-active_modal",
            onOk: () => {
                axios.post(`${deactivateUser}${id}/`, { is_active: checked })
                    .then(res => {
                        this.setState(prevState => ({
                            source: prevState.source.map(
                                el => el.id === id? { ...el, is_active: checked }: el
                            )
                        }))
                        Store.addNotification({
                            title: "Status changed successfully",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2500,
                                onScreen: true
                            }
                        });
                    })
                    .catch(err => {
                        Store.addNotification({
                             title: "There is something wrong",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2500,
                                onScreen: true
                            }
                        })
                    })
            }
        })
    };
    columns = [
        {
            title: 'Name',
            dataIndex: 'dealer_name',
            key: 'DealerName',
            ...this.getColumnSearchProps('dealer_name'),
            sorter: (a, b) => { return a.dealer_name.localeCompare(b.dealer_name) }
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            sorter: (a, b) => { return a.address.localeCompare(b.address) }
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...this.getColumnSearchProps('email'),
            sorter: (a, b) => { return a.email.localeCompare(b.email) }
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
            sorter: (a, b) => a.phone - b.phone
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            sorter: (a, b) => { return a.state.localeCompare(b.state) }
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => { return a.city.localeCompare(b.city) }
        },
        {
            title: 'Zip Code',
            dataIndex: 'zip_code',
            key: 'zip_code',
            sorter: (a, b) => a.zip_code - b.zip_code
        },
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            sorter: (a, b) => { return a.website.localeCompare(b.website) }
        },
        {
            title: 'User Type',
            key: 'UserType',
            ...this.getColumnSearchProps('user_type'),
            sorter: (a, b) => { return a.user_type.localeCompare(b.user_type) },
            render: (record) =>
                <div>
                    {record.user_type === "ClientManager" ? "Client Manager" : record.user_type}
                </div>
        },
        {
            title: 'Assigned Dealers',
            key: 'assignedDealers',
            render: (record) => {
                return <>
            {record.assigned_dealers && record.assigned_dealers.map((data, i) =>
                <div key={i}>- {data}</div>
            )}
            </>
            }
        },
        {
            title: 'Active',
            key: 'ActiveUser',
            align: 'center',
            render: (record) => {
                return <>
            <Switch size="small" checked={record.is_active} onChange={(checked) => this.activateDeactivateUser(checked, record.id)} />
            </>
            }
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            // width: 100,
            render: (record) =>
                <div className='d-flex align-items-center action_wrap'>
                    <EditOutlined onClick={()=>  {this.setState({isModalVisible: true ,setEditId:record.id, setdealers:record.dealers_assigned, userType:record.user_type})}}  className="ml-2" />
                </div>
        }
    ];
    handleCancel = () => {
        this.setState({isModalVisible: false ,setEditId:'', setdealers:[], userType:''})
        window.location.reload()
    };
    showModal = () => {
        this.setState({isModalVisible: true ,setEditId:'', setdealers:[], userType:''})
    };
    componentDidMount() {
        this.setState({ source: this.props.dataSource });
        this.setState({ otherSource: this.props.otherData });
    }
    componentDidUpdate(prevPros, prevState) {
        if (prevPros.otherData.length !== this.props.otherData.length){
            this.setState({ otherSource: this.props.otherData  })
        }
        if (prevPros.dataSource.length !== this.props.dataSource.length){
            this.setState({ source: this.props.dataSource })
        }
    }
    handlepagination = (page) => {
        axios.get(`${managerLink}?page=${page}`)
            .then(res => {

                this.setState({ source: res.data['data'] })
                this.setState({ otherSource: this.otherDataSources(res.data) })

            })
            .catch(err => {
               
            })
};
otherdataSources = (data) => {
    let arr = {page:data['page'], total:data['total'], page_size:data['page_size']}
    return arr
}

    render() {
        return (
            <div>
                <div className='process_buttons'>
                    <Button style={{ display:'inline-block'}} type="primary" className="mb-3 ml-3" onClick={() => this.showModal()} >Add New User</Button>
                </div>
                <TableComp loading={this.props.loading} columns={this.columns} dataSource={this.state.source} />
                {(this.state.otherSource.page) && (
                <div className="pagination-container">
                    <Pagination current={this.state.otherSource.page} showSizeChanger={false} onChange={this.handlepagination} pageSize={100} defaultPageSize={100} total={this.state.otherSource.total} />
                </div>
                )}
                <Modal className="list-user-editmodal" centered visible={this.state.isModalVisible} onCancel={()=> this.handleCancel()} closable footer={null} >
                    <div className='list-user-editmodal-inner'>
                        <ClientSetting id={this.state.setEditId} selectedDealers={this.state.setdealers} userType={this.state.userType}/>
                    </div>
                </Modal>

            </div>
            
        )
    }
}

export default withRouter(ManagerTableColumn)
