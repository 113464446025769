import * as actionType from '../Actions/types'
import { combineReducers } from 'redux'
import user from './../redux/reducers/index';

// Abdullah -- reducer

export interface editorState {
    editor: {
        editor: {
            orignalImage: string,
            removedImage: string,
            imgName:string,
            dealerId:string,
            link: string,
            image_id:string,
            images_edited: boolean,
            original_image:string ,
            image_updated: boolean,  
            csv_id: any ,
            current_id: any ,
            original_url: string,
            stock_number: string
        }
       
    }
    initialUser:{}
}
const initialEditor = {
    editor: {
        orignalImage: '',
        removedImage: '',
        imgName:'',
        dealerId:'',
        link: '',
        csv_id: '',
        current_id: '',
        stock_number:''
    }
}

const editorReducer = (state = initialEditor, action:  actionType.sendToEditorAction) => {
    switch (action.type) {
        case actionType.SEND_TO_EDITOR:
            return {
                ...state,
                editor: action.payload
            }
        
        default:
            return state
    }
}

// Main reducer function
const rootReducer = combineReducers({
    editor: editorReducer,
    user: user,
})
export default rootReducer;