import {useState,useEffect} from 'react'
import axios from 'axios'
import { getSummary } from '../configurations/urls'
import { DatePicker, Select, Button, Table } from 'antd';
import {DownloadOutlined } from '@ant-design/icons';
import { downloadSummary } from '../configurations/urls';
import moment from 'moment';
import * as XLSX from 'xlsx';
const { Column, ColumnGroup } = Table;
const { RangePicker } = DatePicker;
const { Option } = Select;

const DownloadSummary = ({data, allUsers}) => {
    const [tbData,setTbData]=useState([])
    const [otherTbData,setOtherTbData]=useState([])
    const [loading,setLoading]=useState(true)
    const [userDropdown, setUserDropdown] = useState([]);
    const [clientDrop, setClientDrop] =  useState([]);
    const [dateRange, setDateRange] = useState([null, null]);
    const [combineData,setCombineData]=useState([])
    
    useEffect(()=>{
        setUserDropdown(allUsers)
        getTbData()
        setLoading(false)
    },[allUsers])
    const getTbData=async ()=>{
        setLoading(true)
        let startDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        let endDate = moment().format('YYYY-MM-DD');
        if (dateRange[0] && dateRange[1]) {
            startDate = dateRange[0].format('YYYY-MM-DD');
            endDate = dateRange[1].format('YYYY-MM-DD');
        }
        let url = "";
        if (clientDrop.length > 0){
            let selectedDealer = JSON.stringify(clientDrop);
            url = `${downloadSummary}?dealer=${selectedDealer}&start_date=${startDate}&end_date=${endDate}`
        }else{
            url = `${downloadSummary}?start_date=${startDate}&end_date=${endDate}`
        }
        axios.get(`${url}`)
            .then(res => {
                setTbData(res.data.all_dealer_info)
                setOtherTbData(res.data.all_record_info)
                setCombineData(res.data.combine_results)
                setLoading(false)
            })
            .catch(err => {
                setTbData([])
                setOtherTbData([])
                setCombineData([])
                setLoading(false)
            })

    }
    const handleChange = (value) => {
        setClientDrop(value)
    };
    const handleDateChange = (dates) => {
        setDateRange(dates);
    }
    const applyFilter = () => {
        getTbData()
    }
    const exportToExcel = () => {
        const ws1 = XLSX.utils.json_to_sheet(tbData);
        const ws2 = XLSX.utils.json_to_sheet(otherTbData);

        ws1['A1'] = { t: 's', v: 'Dealer Name', s: { font: { bold: true } } };
        ws1['B1'] = { t: 's', v: '# of images', s: { font: { bold: true } } };

        ws2['A1'] = { t: 's', v: 'Dealer Name', s: { font: { bold: true } } };
        ws2['B1'] = { t: 's', v: 'VIN', s: { font: { bold: true } } };
        ws2['C1'] = { t: 's', v: 'Stock Number', s: { font: { bold: true } } };
        ws2['D1'] = { t: 's', v: 'Date Of First Sent', s: { font: { bold: true } } };
        ws2['E1'] = { t: 's', v: '# of images', s: { font: { bold: true } } };
        ws2['F1'] = { t: 's', v: '# of times sent', s: { font: { bold: true } } };
        ws2['G1'] = { t: 's', v: 'Image Info', s: { font: { bold: true } } };
        
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws1, 'Total');
        XLSX.utils.book_append_sheet(wb, ws2, 'Summary');

        let startDate = moment().subtract(1, 'days').format('YYYY/MM/DD');
        let endDate = moment().format('YYYY/MM/DD');
        if (dateRange[0] && dateRange[1]) {
            startDate = dateRange[0].format('YYYY/MM/DD');
            endDate = dateRange[1].format('YYYY/MM/DD');
        }
        const file_name = "Summary("+startDate+"-"+endDate+").xlsx"
    
        XLSX.writeFile(wb, file_name);
    };
    const columns = [
        {
            title: 'Dealer Name',
            dataIndex: 'name',
            key: 'name',
            className: 'download-summary-dealer',
        },
        {
            title: '# of images',
            dataIndex: 'total',
            key: 'total',
        }
    ]

    const second_columns = [
        {
            title: 'Dealer Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
        },
        {
            title: 'Stock Number',
            dataIndex: 'stock_number',
            key: 'stock_number',
        },
        {
            title: 'Date Of First Sent',
            dataIndex: 'first_sent_at',
            key: 'first_sent_at',
        },
        {
            title: '# of images',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: '# of times sent',
            dataIndex: 'total_sent',
            key: 'total_sent',
        },
        {
            title: 'Image Info',
            dataIndex: 'img_info',
            key: 'img_info',
            render: (text) => {
                const segments = text.split(';').map((segment, index) => (
                  <div key={index}>{segment.trim()}</div>
                ));
          
                return (
                  <div>
                    {segments}
                  </div>
                );
            }
        }
    ]

    return (
        <div>
            <div className='container ml-0 mr-0' style={{maxWidth:'100%'}}>
                <div className='row'>
                    <div className='col-md-2'>
                        <RangePicker
                            onChange={handleDateChange}
                            defaultValue={[moment().subtract(1, 'days'), moment()]}
                        />
                    </div>
                    <div className='col-md-3'>
                        <Select
                            className='download-inventory-select'
                            style={{width:'100%'}}
                            mode="multiple"
                            value={clientDrop}
                            onChange={handleChange}
                            placeholder='Select Dealer'
                            maxTagCount='responsive'
                        >
                            {userDropdown && userDropdown.map(((item , i) => (
                                <Option value={item.dealer_id} key={i}>{item.dealer_name} ({item.dealer_id})</Option>
                            )))}
                        </Select>
                    </div>
                    <div className='col-md-1'>
                        <Button style={{display:"inline-block"}} type="primary" onClick={applyFilter}>Apply</Button>
                    </div>
                    <div className='col-md-6'>
                        <Button style={{float:"right"}} type="primary" className="mr-5" onClick={exportToExcel} icon={<DownloadOutlined />}>Download</Button>
                    </div>
                </div>
                <Table className='download-summary mt-3' dataSource={combineData} loading={loading} pagination={false} columns={columns} showHeader={combineData.length > 0}
                    expandable={{
                        expandedRowRender: (record) => (
                            <Table className='download-summary mt-3' dataSource={record.records} loading={loading} pagination={false} columns={second_columns}/>
                        ),
                        columnWidth: 100,
                    }}
                />
            </div>
        </div>
    )
}

export default DownloadSummary
