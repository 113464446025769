import React, { useState, useEffect } from 'react'
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { saveAs } from 'file-saver';
import * as JSZipUtils from 'jszip-utils';
import { Select, Empty, Button } from 'antd'
import { adminLink, getInventoryImage, convertImage, getInventoryReview } from '../configurations/urls';
import axios from 'axios';
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

const { Option } = Select;
const InventoryImages = (props) => {
    const [images, setImages] = useState([]);
    const [currentImage, setCurrentImage] = useState();
    const [userDropdown, setUserDropdown] = useState([]);
    const [clientDrop, setClientDrop] =  useState();
    const [imageLoading, setImageLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [pendingFile, setPendingFiles] = useState(false);

    const fetchUsers = () => {
        axios.get(adminLink)
            .then(res => {
                const storedClientDrop = window.localStorage.getItem("bulkImageDropdown");
                let dealer_exists = false;
                res.data['all_users'].map(dealer => {
                    if (storedClientDrop != null && dealer.dealer_id === storedClientDrop){
                        dealer_exists = true;
                    }
                });
                if (storedClientDrop === null || !dealer_exists){
                    setClientDrop(res.data['all_users'][0].dealer_id)
                }
                setUserDropdown(res.data['all_users'])
            })
            .catch(err => {
               
            })
    }

    const fetchInventoryImages = () => {
        if(clientDrop){
            const url = `${getInventoryReview}?dealer=${clientDrop}&page=${page}&page_size=10`;
            axios.get(`${url}`)
            .then(res => {
                if(res.status==200 ){
                    setImages([...images, ...inventoryDataSource(res.data['data'])])
                    setPendingFiles(res.data['pending_file'])
                    setPage(page+1);
                if (!res.data['links'].next){
                    setHasMore(false)
                }
            }
            })
            .catch(err => {
                
            })
        }
    }
    useEffect(() => {
        setClientDrop(window.localStorage.getItem("bulkImageDropdown"))
        fetchUsers();
    }, [])

    useEffect(() => {
        fetchInventoryImages()
    }, [clientDrop])

    const handleSelect = (index) => {
        const nextImages = images.map((image, i) =>
            i === index ? { ...image, isSelected: !image.isSelected } : image
        );
        setImages(nextImages);
    };

    const handleClick = (index, item) => {
        setCurrentImage(images[index]);
    }

    const handleClose = () => {
        setCurrentImage("");
    };

    var today = new Date(),
    date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
    var JSZip = require("jszip");
    var zip = new JSZip();
    const handleDownloadClick = () => {
        setImageLoading(true);
        var count = 0;
        var zipFilename = 'images_' + date +'.zip';
        const imagesAsArray = []
        images.forEach(d => {
            if (d.isSelected){
                imagesAsArray.push({'url': d.original, 'name': d.thumbnailCaption})
            }
        })
        imagesAsArray.forEach(function(url){
            var filename = url.name;
            axios.get(`${convertImage}?image=${url.url}`)
                .then(res => {
                    zip.file(filename, res.data['data'], {base64:true});
                    count++;
                    if (count == imagesAsArray.length) {
                        zip.generateAsync({type:'blob'}).then(function(content) {
                            saveAs(content, zipFilename);
                        });
                        setImageLoading(false);
                    }
                })
                .catch(err => {
                    setImageLoading(false);
                })
        });
    }
    
    const handleChange = (value) => {
        setImages('')
        setClientDrop(value)
        setPage(1)
        setHasMore(true)
        window.localStorage.setItem('bulkImageDropdown', value)
    };
    const inventoryDataSource = (data) => {
    
        let arr = []
        data.length > 0 && data.forEach((d, i) => {
         
            if (d) {
                arr.push({ "src": d.origional_url, "original":d.origional_url, "stock_number":d.stock_number, "width": 300, "height": 200,
                    thumbnailCaption: d.dealer_id+'_'+d.stock_number+'_1_'+d.id.toString()+'.jpg',
                    tags: [
                        { value: "Original", title: "Original" },
                    ]
                })
                arr.push({ "src": (d.overlay_added ? d.overlay_image_url : d.editted_image_url), "original":(d.editted_image_url), "stock_number":d.stock_number, "width": 300, "height": 200,
                    thumbnailCaption: d.dealer_id+'_'+d.stock_number+'_2_'+d.id.toString()+'.jpg',    
                    tags: [
                        { value: d.image_tag, title: d.image_tag }
                    ]
                })
            }
        })
        return arr
    }

    return (
        <>
        { !!imageLoading &&
            <div className='loader'>
                <Spin />
                <h3 className="ml-3 text-white" >Downloading Images Please Wait...</h3>
            </div>
        }
            <Select className='inventory-select invertry-list' value={clientDrop} onChange={handleChange}>
                {userDropdown && userDropdown.map(((item , i) => (
                    <Option value={item.dealer_id} key={i}>{item.dealer_name} ({item.dealer_id})</Option>
                )))}
            </Select>
            {images && images.length > 0 ?
            <div>
            <InfiniteScroll
                dataLength={images.length}
                next={() => {
                    fetchInventoryImages();
                }}
                hasMore={hasMore}
                loader={
                    <div style={{textAlign:'center'}}>
                        <span className='mt-4' style={{color:'rgba(0, 0, 0, 0.25)', display:'block'}}>Scroll down to load more...</span>
                    </div>
                }
                endMessage={
                    <div style={{ textAlign: "center" }}>
                        <span className='mt-4' style={{color:'rgba(0, 0, 0, 0.25)', display:'block'}}>End of results</span>
                    </div>
                }
            >
                <div style={{ minHeight: "70vh"}}>
                    <Gallery images={images} onSelect={handleSelect} onClick={handleClick} margin={5} />
                </div>
            </InfiniteScroll>
            <Button disabled={pendingFile} type="primary" className="mt-3" onClick={handleDownloadClick}>
                Download
            </Button>
            </div>
            :
                <div>
                    <Empty />
                </div>
            }
            {!!currentImage && (
            <Lightbox
                enableZoom={false}
                mainSrc={currentImage.original}
                imageTitle={currentImage.caption}
                mainSrcThumbnail={currentImage.src}
                onCloseRequest={handleClose}
            />
            )}
        </>
    )
}

export default InventoryImages
