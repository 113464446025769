import {useState,useEffect} from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { Tabs } from 'antd';
import {  FundOutlined, DownloadOutlined } from '@ant-design/icons';
import SummaryTable from './SummaryTable';
import DownloadSummary from './DownloadSummary';
const { TabPane } = Tabs;

const AdminSummary = ({users, allUsers}) => {
    const [active, setActive] = useState(null);
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
       
        setActive(pathname.replace('/',""))
     }, [pathname])

    const changeTab=(tab)=>{
        history.push(`/${tab}`)
        setActive(tab)
    }

    return (
        <>
        {/* <Tabs defaultActiveKey="admin-summary" activeKey={active} onChange={changeTab}>
            <TabPane
                tab={
                    <span>
                        <FundOutlined />
                        Summary
                    </span>
                }
                key="admin-summary"
            >
                <SummaryTable data={users} />
            </TabPane>
            <TabPane
                tab={
                    <span>
                        <DownloadOutlined />
                        Download
                    </span>
                }
                key="download-summary"
            > */}
                <DownloadSummary data={users} allUsers={allUsers} />
            {/* </TabPane>
        </Tabs> */}
        </>
    )
}

export default AdminSummary
