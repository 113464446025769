import React, { useEffect, useRef, useState } from "react";
import {
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import "react-image-gallery/styles/css/image-gallery.css";
import { triggerBase64Download } from "react-base64-downloader";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Spin, Upload, Button } from "antd";
import { useHistory } from "react-router-dom";
import { replaceManualImage, addImageCsv } from "../configurations/urls";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Store } from 'react-notifications-component';

const ManualSliderImageViwer = ({ onClose, sliderImageData, SliderImageId, resultType}) => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [imageEdited, setImageEdited] = useState("");
  const [imageUpdated, setImageUpdated] = useState("");
  const [imageMarked, setImageMarked] = useState("");
  const [sliderIndex, setSliderIndex] = useState(0);
  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  useEffect(() => {
    setOriginalImage("");
    setImageEdited("");
    setImageUpdated("");
    setImageMarked("");
    if (!!sliderImageData.length) {
      let listss = document.querySelectorAll(".slick-current");
      if(!!listss && listss.length){
      setTimeout(()=>{
        listss[0].classList.add("current")
        
        handleSliderClick(sliderImageData[0] ,0)
      },400)
      }
    }
  }, [sliderImageData]);
  const returnCurrentImage = () => {
    for (let i in sliderImageData) {
      if (i == sliderIndex) {
       
        return sliderImageData[i].editted_image;
      }
    }
  };
  const download = () => {
    setPending("download");

    const currentImage = returnCurrentImage();
    toDataUrl(currentImage+"?v=1655290884560", function (myBase64) {
      let b64 = myBase64.replace(/^data:image\/jpeg;base64,/, "data:image/jpg;base64,");
      triggerBase64Download(b64, "qrcode");
      setPending("");
    });
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadImage = async (options) => {

    let data;
    for (let i in sliderImageData) {
      if (i == sliderIndex) {
        data = sliderImageData[i];
      }
    }
    setPending("upload");
    const { file } = options;
    let b64 = await toBase64(file);
    // b64 = b64.replace("data:image/png;base64,", "");
    b64 = b64.replace(/^data:image\/[a-z]+;base64,/, "");
    axios
      .post(
        `${replaceManualImage}${
          SliderImageId
        }/${data.image_id}/`,
        {
          image_data: b64,
          image_id: data.image_id,
          images_edited: data.images_edited,
          link: data.link,
          image_updated: data.image_updated
        }
      )
      .then((res) => {
        setOriginalImage(res.data.editted_image);
        setImageEdited(res.data.images_edited);
        setImageUpdated(res.data.image_updated);
        setImageMarked(res.data.marked_for_rework);
        for(let i in sliderImageData){
          if(sliderImageData[i].original_image == res.data.original_image){
            sliderImageData[i].editted_image = res.data.editted_image
            sliderImageData[i].images_edited = res.data.images_edited
            sliderImageData[i].image_updated = res.data.image_updated
          }
        }
        setPending("");
      })
      .catch((err) => {
     
        setPending("");
        Store.addNotification({
          title: err.response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true
          }
      });
      });
  };
  const addToCsv = () => {
setPending("add");
    let data;
    for (let i in sliderImageData) {
      if (i == sliderIndex) {
        data = sliderImageData[i];
      }
    }
    axios
      .post(
        `${addImageCsv}${
          SliderImageId
        }/${data.image_id}/`,
        {
          image_id: data.image_id,
          images_edited: data.images_edited,
          link: data.link,
          image_updated: data.image_updated
        }
      )
      .then((res) => {
        setOriginalImage(res.data.editted_image);
        setImageEdited(res.data.images_edited);
        setImageUpdated(res.data.image_updated);
        setImageMarked(res.data.marked_for_rework);
        for(let i in sliderImageData){
          if(sliderImageData[i].original_image == res.data.original_image){
            sliderImageData[i].editted_image = res.data.editted_image
            sliderImageData[i].images_edited = res.data.images_edited
            sliderImageData[i].image_updated = res.data.image_updated
          }
        }
        setPending("");
      })
      .catch((err) => {
     
        setPending("");
      });
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleSliderClick = (data, i,e) => {
   if(!!e){
    let list = document.querySelectorAll(".current");
    for (var j = 0; j < list.length; ++j) {
     list[j].classList.remove('current');
   }
    e.currentTarget.parentElement.parentElement.classList.add('current');
  }
  
    setSliderIndex(i);
    setOriginalImage(data.editted_image);
    setImageEdited(data.images_edited);
    setImageUpdated(data.image_updated);
    setImageMarked(data.marked_for_rework);
  };
  return (
    
    <div className="img-viewer">
      <div className="overlay"></div>
        <div className="slider-section">
         {!!sliderImageData.length &&
          <div className="body"><div className="close" onClick={onClose}>
              <CloseOutlined />
            </div>
            <div className="slider-content">
            <div>
                <Slider {...settings}>
                 
                    {sliderImageData.map((data, i) => (
                      
                      <div key={i} id={data.image_id} className="slider-slick" onClick={(e) => handleSliderClick(data, i,e)}>
                        <img src={data.editted_image} style={{border: resultType ? null : data.marked_for_rework ?'3px solid #FFD700': data.image_updated ? "3px solid #7FFF00" : "3px solid #FF0000"}} alt="wdww" />
                        <div className="mt-5" style={{textAlign:'center'}}>
                          {!! resultType ? 
                          <Button
                          loading={pending === "download"}
                          onClick={() => download()}
                          style={{display:'inline-block'}}
                          type={"primary"}
                        >
                          Download
                        </Button>
                        :<>{resultType}<Button
                          disabled={data.image_updated ? true : false}
                          onClick={() => addToCsv()}
                          style={{display:'inline-block', marginRight:'10px'}}
                          type={"primary"}
                        >
                          Add to CSV
                        </Button>
                            <Upload
                              customRequest={uploadImage}
                              maxCount={1}
                              showUploadList={false}
                              accept={'.jpeg,.jpg'}
                              loading={pending === "add"}
                            >
                            <Button
                              loading={pending === "upload"}
                              icon={<UploadOutlined />}
                            >
                              Replace
                            </Button>
                            </Upload>
                            </>
}
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
            </div>
          </div>}
        </div>
    
    </div>
  );
};
export default withRouter(ManualSliderImageViwer);
