import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Button, Upload, Image, Checkbox } from 'antd';
import { UploadOutlined  ,PlusOutlined , CloseOutlined} from '@ant-design/icons';
import Navbar from './Navbar';
import axios from 'axios'
import { withRouter } from 'react-router-dom';
import { userPreferences } from '../configurations/urls';
import { Radio, Popover } from 'antd';

const Register = (props) => {
    const [preview,setPreview]=useState({newfile:'',oldfile:'' , AddFile:''})
    const [stateFile,setStateFile]=useState({newfile:'',oldfile:'' , AddFile:''})
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [loading,setLoading]=useState(false)
    const [isSecondVehicle , setIsSecondVehicles] = useState(false)
    const [form] = Form.useForm();
    const [otherFields, setOtherFields] = useState("lot_loc_2")

    useEffect(()=>{
        
        // form.setFieldsValue({ user: data })
        // form.setFieldsValue({
        //     [used_common_options]: "first_last"
        //   });
        //   form.setFieldsValue({
        //     [second_common_options]: "first_last"
        //   });
        //   form.setFieldsValue({
        //     [new_common_options]: "first_last"
        //   });
        //   form.setFieldsValue({
        //     [use_new_common_options]: false
        //   });
        //   form.setFieldsValue({
        //     [use_second_common_options]: false
        //   });
        //   form.setFieldsValue({
        //     [use_used_common_options]: false
        //   });
       
    },[])
    const onFinish = (values) => {
       
        setError(null)
        setLoading(true)
        if(values.user.new_enhance_all===undefined){
            values.user.new_enhance_all=false
        }
        if(values.user.used_enhance_all===undefined){
            values.user.used_enhance_all=false
        }
        const formData = new FormData()
        for (var key in values.user) {
            if(key=="second_enhance_all" &&values.user[key]==undefined){
                var value = values.user[key];
                formData.append(key, false)
            }else{
            var value = values.user[key];
            formData.append(key, value)
            }
        }
       
        formData.append('new_background_image', stateFile.newfile)
        formData.append('used_background_image', stateFile.oldfile)
        if(!!isSecondVehicle){
        formData.append('second_background_image', stateFile.AddFile)
        }
        formData.append('second_lot_status', isSecondVehicle)
        formData.append('user_added', true)
      
        axios.post(userPreferences, formData)
            .then(res => {
                setLoading(false)
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(null)
                    window.location.replace('/public')
                }, 2000)
                //props.history.push('/')
            })
            .catch(err => {
                setLoading(false)
                if(err.response)
                setError(err.response.data.message)
                else
                setError('There is something wrong')}) 
    };
    const inputConfig = {
        rules: [
          {
            required: true,
            message: 'This field is required!',
          },
        ],
      };
      const dateConfig = {
        rules: [
          {
            required: true,
            message: 'This field is required!',
          },
        ],
      };
      
      const uploadConfig = {
        rules: [
          {
            required: true,
            message: 'This field is required!',
          },
        ],
      };
    const customRequestFun = (options) => {
        const { onSuccess, file } = options;
        setStateFile({...stateFile,newfile:file})
        let reader = new FileReader();

        reader.readAsDataURL(file);
      
        reader.onload = function() {
          setPreview({...preview,newfile:reader.result})
        };
              onSuccess(file)
    };
    const AddCustomRequestFun = (options) => {
        const { onSuccess, file } = options;
        setStateFile({...stateFile,AddFile:file})
        let reader = new FileReader();

        reader.readAsDataURL(file);
      
        reader.onload = function() {
          setPreview({...preview,AddFile:reader.result})
        };
              onSuccess(file)
    };
    const customRequestFun1 = (options) => {
        const { onSuccess, file } = options;
        setStateFile({...stateFile,oldfile:file})
        let reader = new FileReader();

        reader.readAsDataURL(file);
      
        reader.onload = function() {
          setPreview({...preview,oldfile:reader.result})
        };
        onSuccess(file)
    };
    const OpenNewVehicals =() => {
        setPreview({...preview,AddFile:""})
        if(!!isSecondVehicle){
        setIsSecondVehicles(false)
        }
        else{
            setIsSecondVehicles(true)

        }
        
    }
    const handleOptionChange = (e) => {
        setOtherFields(e.target.value)
    };
 
  
    return (
        <>
            <Navbar />
            <div className="container py-2">
                <div className="row py-5 justify-content-center">
                    <div className="col-md-8">
                        <div className="box">
                            <Form name="nest-messages" layout="horizontal" onFinish={onFinish}>
                            <h5>FTP Information</h5>
                               <Form.Item name={['user', 'host']} label="Host" rules={[{required: true ,message:"This field is required"}]} >
                                            <Input type="text"/>
                                        </Form.Item>
                                  <Form.Item name={['user', 'port']} label="Port" rules={[{required: true ,pattern: new RegExp(/^[0-9]+$/),message:"This field is required"}]}>
                                            <Input type="text" />
                                        </Form.Item>
                               
                            
                                       <Form.Item  name={['user', 'username']} label="Username" rules={[{required: true , message:'This field is required'}]} >
                                            <Input type="text" placeholder="Username"/>
                                        </Form.Item>
                                   
                                   <Form.Item name={['user', 'password']} label="Password"  rules={[{ required: true, message: 'This field is required' }]}>
                                            <Input  type="password" placeholder="Password"  />
                                        </Form.Item>
                                  
                                <h5 className="mt-4">Exterior Background</h5>
                                <h6>New Vehicles</h6>
                                <Form.Item name={['user', 'new_num_images']}  {...inputConfig} label="Num images to enchance per vehicle">
                                    <Input />
                                </Form.Item>
                               
                               
                                <Form.Item name={['user', 'new_only_enhance_min_num']} {...inputConfig} label="Only enhance if vehicle has a minimun of" >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name={['user', 'new_enhance_all']} valuePropName="checked" label="Enhance all existing inventory">
                                <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item name={['user', 'new_enhance_after']} {...dateConfig} label="Enhance inventory only after">
                                    <Input type="date" format={'YYYY-MM-DD'}  />
                                </Form.Item>
                                <Form.Item name={['user', 'new_background_image']} {...uploadConfig} label="Upload background image">
                                <div className="d-flex">
                                    <Upload listType="picture" customRequest={customRequestFun} >
                                        <Button  icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                    <div className="ml-3">
                                    <Image
                                        width={80}
                                        src={preview.newfile}
                                    />
                                    </div>
                                    
                                    </div>
                                </Form.Item>
                                {!!isSecondVehicle?
                                 <Button icon={<CloseOutlined />} onClick={()=>OpenNewVehicals()}>No Need To Add New Vehicles</Button>:
                                <Button icon={<PlusOutlined />} onClick={()=>OpenNewVehicals()}>Add New Vehicles</Button>}
                                
                             {!!isSecondVehicle&&
                             <>
                             <Form.Item name={['user', 'background_option']} label="Background options" initialValue={"lot_loc_2"}>
                                <Radio.Group onChange={handleOptionChange} value={otherFields}>
                                    <Radio value={"lot_loc_2"} defaultChecked>Lot Location</Radio>
                                    <Radio value={"other_csv_field"}>Other field</Radio>
                                </Radio.Group>
                                </Form.Item>
                                {otherFields == "other_csv_field" &&
                                <>
                                <div className="row">
                                    <div className='col-md-6'>
                                        <Form.Item name={['user', 'other_csv_option']} {...inputConfig} label="Field name">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Item name={['user', 'other_csv_value']} {...inputConfig} label="Field value">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                </div>
                                </>
                                }
                                {/* <h6>No Need To Add New Vehicles</h6> */}
                                <Form.Item name={['user', 'second_num_images']}  {...inputConfig} label="Num images to enchance per vehicle">
                                    <Input />
                                </Form.Item>
                               
                                <Form.Item name={['user', 'second_only_enhance_min_num']} {...inputConfig} label="Only enhance if vehicle has a minimun of" >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name={['user', 'second_enhance_all']} valuePropName="checked" label="Enhance all existing inventory">
                                <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item name={['user', 'second_enhance_after']} {...dateConfig} label="Enhance inventory only after">
                                    <Input type="date" format={'YYYY-MM-DD'}  />
                                </Form.Item>
                                <Form.Item name={['user', 'second_background_image']} {...uploadConfig} label="Upload background image">
                                <div className="d-flex">
                                    <Upload listType="picture" customRequest={AddCustomRequestFun} >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                    <div className="ml-3">
                                    <Image
                                        width={80}
                                        src={preview.AddFile}
                                    />
                                    </div>
                                    
                                    </div>
                                </Form.Item>
                                </>}

                                
                                <h6 className="pt-4">Used Vehicles</h6>
                                <Form.Item name={['user', 'used_num_images']} {...inputConfig} label="Num images to enchance per vehicle">
                                    <Input />
                                </Form.Item>
                              
                                <Form.Item name={['user', 'used_only_enhance_min_num']} {...inputConfig} label="Only enhance if vehicle has a minimun of" >
                                    <InputNumber />
                                </Form.Item>
                                <Form.Item name={['user', 'used_enhance_all']} valuePropName="checked" label="Enhance all existing inventory">
                                <Checkbox></Checkbox>
                                </Form.Item>
                                <Form.Item name={['user', 'used_enhance_after']} {...dateConfig} label="Enhance inventory only after">
                                    <Input type="date" format={'YYYY-MM-DD'} />
                                </Form.Item>
                                <Form.Item name={['user', 'used_background_image']} {...uploadConfig} label="Upload background image">
                                <div className="d-flex">
                                    <Upload  listType="picture" customRequest={customRequestFun1} >
                                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                    </Upload>
                                    <div className="ml-3">
                                    <Image
                                        width={80}
                                        src={preview.oldfile}
                                    />
                                    </div>
                                    </div>
                                </Form.Item>
                                <h6 className="pt-4">Notes/Comments:</h6>
                                <Form.Item name={['user', 'notes']} {...inputConfig} >
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item>
                                    <div className="d-flex">
                                    <Button type="primary" loading={loading} htmlType="submit">
                                        Save Info
                                </Button>
                                {error && <p className="text-danger ml-3">
                                    {error}
                                    </p>}
                                {success && <p className="text-success ml-3">
                                    Information Successfully Submitted
                                    </p>}
                                    </div>
                                   
                                </Form.Item>
                              
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Register)
