import React from 'react'
import TableComp from './TableComp'
import { Input, Button, Space, Popconfirm ,Modal, Tag, Switch, Form, Upload, Image, Select, DatePicker} from 'antd'
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words';
import { SearchOutlined ,EditOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getFrameImages, addOverlayImage, getAllDealers, getOverlayImages, updateOverlayImage, updateOverlayStatus } from '../configurations/urls';
import { Pagination, Spin } from 'antd'
import ClientSetting from './ClientSetting';
import { Store } from 'react-notifications-component';
import moment from 'moment';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
const { confirm } = Modal;
const {Option} = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const weekFormat = 'MM/DD';
const monthFormat = 'YYYY/MM';

class OverlayTableColumn extends React.Component {

    state = {
        searchText: '',
        isModalVisible:false,
        setEditId:"",
        searchedColumn: '',
        isModalVisible: false,
        visible: false,
        index: 0,
        source: '',
        otherSource: 1,
        sorceData: this.props.dataSource,
        setdealers: [],
        userType : '',
        preview:{ frameFile: ''},
        stateFile:{frameFile: ''},
        imageName:'',
        imageLoading:false,
        typeDrop: [],
        userDropdown: [],
        startDate: '',
        endDate: '',
        imageLoader: false
    };
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90, alignItems: 'center', display: 'inline-block' }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    activateDeactivateFrame = (checked, id) => {
        let modal_txt = "Are you sure you want to deactivate this overlay ?";
        if (checked){
            modal_txt = "Are you sure you want to activate this overlay ?"
        }
        confirm ({ 
            icon: <ExclamationCircleOutlined />,
            content: modal_txt,
            className: "confirm-active_modal",
            onOk: () => {
                this.setState({imageLoader: true})
                axios.put(`${updateOverlayStatus}${id}/`, { status: checked })
                    .then(res => {
                        this.setState({imageLoader: false})
                        if(res.data.message != "Ok"){
                            var msgString = res.data.message;
                            var type = "danger";
                        }else{
                            this.setState(prevState => ({
                                source: prevState.source.map(
                                    el => el.id === id? { ...el, active: checked }: el
                                )
                            }))
                            var msgString = "Status changed successfully";
                            var type = "success";
                        }
                        Store.addNotification({
                            title: msgString,
                            type: type,
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2500,
                                onScreen: true
                            }
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 2500)
                    })
                    .catch(err => {
                        this.setState({imageLoader: false})
                        Store.addNotification({
                             title: "There is something wrong",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animate__animated", "animate__fadeIn"],
                            animationOut: ["animate__animated", "animate__fadeOut"],
                            dismiss: {
                                duration: 2500,
                                onScreen: true
                            }
                        })
                    })
            }
        })
    };
    columns = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'Image',
            render: (image, record, index) =><>
                <Image width={80} style={{ margin: '2px', cursor: 'pointer' }}
                    src={record.image_url} />
                
                </>
        },
        {
            title: 'Name',
            dataIndex: 'overlay_name',
            key: 'Name',
            ...this.getColumnSearchProps('overlay_name'),
            sorter: (a, b) => { return a.overlay_name.localeCompare(b.overlay_name) }
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'CreatedAt',
            sorter: (a, b) => { return a.created_at.localeCompare(b.created_at) }
        },
        {
            title: 'Start Date',
            key: 'StartDate',
            sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
            render: (record) => {
                return <>
                <div>{moment(record.start_date).format("MM/DD/YYYY")}</div>
                </>
            }
        },
        {
            title: 'Start Time',
            key: 'StartTime',
            render: (record) => {
                return <>
                <div>{moment(record.start_date).format("HH:mm")}</div>
                </>
            }
        },
        {
            title: 'End Date',
            key: 'EndDate',
            sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
            render: (record) => {
                return <>
                <div>{moment.utc(record.end_date).local().format("MM/DD/YYYY")}</div>
                </>
            }
        },
        {
            title: 'End Time',
            key: 'EndTime',
            render: (record) => {
                return <>
                <div>{moment.utc(record.end_date).local().format("HH:mm")}</div>
                </>
            }
        },
        {
            title: 'Dealers',
            key: 'AssignedDealers',
            render: (record) => {
                return <>
                    {record.assigned_dealers && record.assigned_dealers.map((data, i) =>
                        <div key={i}>- {data}</div>
                    )}
                </>
            }
        },
        {
            title: 'Active',
            key: 'ActiveUser',
            align: 'center',
            render: (record) => {
                return <>
            <Switch size="small" checked={record.active} onChange={(checked) => this.activateDeactivateFrame(checked, record.id)} />
            </>
            }
        },
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (record) =>
                <div className='d-flex align-items-center action_wrap'>
                    <EditOutlined onClick={()=>  {this.handleEdit(record.id)}}  className="ml-2" />
                </div>
        }
    ];
    handleCancel = () => {
        this.setState({isModalVisible: false ,setEditId:''})
        window.location.reload()
    };
    showModal = () => {
        // let url = `${getAllDealers}`;
        // axios.get(`${url}`)
        //     .then(res => {
        //         this.setState({userDropdown: (res.data['data'])})
        //     })
        //     .catch(err => {
               
        //     })
        this.setState({isModalVisible: true ,setEditId:''})
    };
    componentDidMount() {
        let url = `${getAllDealers}`;
        axios.get(`${url}`)
            .then(res => {
                this.setState({userDropdown: (res.data['data'])})
            })
            .catch(err => {
               
            })
        this.setState({ source: this.props.dataSource });
        this.setState({ otherSource: this.props.otherData });
    }
    componentDidUpdate(prevPros, prevState) {
        if (prevPros.otherData.length !== this.props.otherData.length){
            this.setState({ otherSource: this.props.otherData  })
        }
        if (prevPros.dataSource.length !== this.props.dataSource.length){
            this.setState({ source: this.props.dataSource })
        }
    }
    handlepagination = (page) => {
        axios.get(`${getFrameImages}?page=${page}`)
            .then(res => {
                this.setState({ source: res.data['data'] })
                this.setState({ otherSource: this.otherDataSources(res.data) })

            })
            .catch(err => {
               
            })
    };
    otherdataSources = (data) => {
        let arr = {page:data['page'], total:data['total'], page_size:data['page_size']}
        return arr
    }
    customRequestFun = (options) => {
        const { onSuccess, file } = options;
        this.setState({ stateFile: {frameFile: file }})
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({ preview:{frameFile: reader.result }})
        };
        onSuccess(file)
    };
    onFinish = (values) => {
        this.setState({imageLoading: true})
        let formData = new FormData();
        formData.append('image_url', this.state.stateFile.frameFile);
        formData.append('overlay_name', values['overlay_name']);
        formData.append('active', true);
        formData.append('dealer', JSON.stringify(values['dealer']));
        formData.append('date_range', values['date_range']);
        let put;
        let id = this.state.setEditId;
        let success_text = '';
        if (id) {
            success_text = "Overlay updated successfully"
            put = axios.put(`${updateOverlayImage}${id}/`, formData)
        }
        else {
            success_text = "Overlay added successfully"
            put = axios.post(`${addOverlayImage}`, formData)
        }
        put.then(res => {
            this.setState({imageLoading: false})
            Store.addNotification({
                title: success_text,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
            setTimeout(() => {
                window.location.reload();
            }, 2500)
        })
        .catch(err => {
            let errorText = "";
            if (err.response){
                errorText = err.response.data.message
            }else{
                errorText = "Something went wrong"
            }
            this.setState({imageLoading: false})
            Store.addNotification({
                title: errorText,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
        })
    };
    handleEdit = (id) => {
        let url = `${getAllDealers}`;
        axios.get(`${getOverlayImages}${id}/`)
            .then(async res => {
                let data = res.data;
                this.setState({ preview: {frameFile: data.image_url }})
                this.setState({ imageName: data.overlay_name })
                this.setState({ typeDrop: data.dealer })
                this.setState({ startDate: moment.utc(data.start_date).local().format('YYYY-MM-DD HH:mm') })
                this.setState({ endDate: moment.utc(data.end_date).local().format('YYYY-MM-DD HH:mm') })
                this.setState({isModalVisible: true ,setEditId:id})
            })
            .catch(err => {})
        // axios.get(`${url}`)
        //     .then(res => {
        //         this.setState({userDropdown: (res.data['data'])})
        //     })
        //     .catch(err => {
               
        //     })
    }

    handleTypeChange = (value) => {
        this.setState({ typeDrop: value })
    }

    render() {
        return (
            <>
            <div>
            { !!this.state.imageLoader &&
                <div className='loader'>
                    <Spin />
                    <h3 className="ml-3 text-white" >Please Wait...</h3>
                </div>
            }
                <div className='process_buttons'>
                    <Button style={{ display:'inline-block'}} type="primary" className="mb-3 ml-3"  onClick={() => this.showModal()} >Add New Image</Button>
                </div>
                <TableComp loading={this.props.loading} columns={this.columns} dataSource={this.state.source} />
                {(this.state.otherSource.page) && (
                <div className="pagination-container">
                    <Pagination current={this.state.otherSource.page} showSizeChanger={false} onChange={this.handlepagination} pageSize={100} defaultPageSize={100} total={this.state.otherSource.total} />
                </div>
                )}
                <Modal className="list-user-editmodal" centered visible={this.state.isModalVisible} onCancel={()=> this.handleCancel()} closable footer={null} >
                    <div className='list-user-editmodal-inner'>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="box">
                                        <Form name="nest-messages" layout="horizontal" onFinish={this.onFinish}>
                                            <h5>Add Overlay</h5>
                                            <div className="row">
                                                <div className="col-md-12">
                                                <Form.Item name="image_url" label="Upload Overlay Image" rules= {!this.state.preview.frameFile ? [{required:true, message:"This field is required"}] :""}>
                                                    <div className="d-flex">
                                                        <Upload accept={'.png'} listType="picture" maxCount={1} customRequest={this.customRequestFun}>
                                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                                        </Upload>
                                                        <div className="ml-3">
                                                            <Image
                                                                width={100}
                                                                src={this.state.preview.frameFile}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <Form.Item initialValue={this.state.imageName} name="overlay_name" label="Name" rules= {[{ required: true, message: 'This field is required!'}]}>
                                                        <Input type="text" />
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                    <Form.Item initialValue={this.state.typeDrop} name="dealer" labelCol={{ span: 24 }} label="Select Dealer" rules= {[{ required: true, message: 'This field is required!'}]}>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: '100%' }}
                                                            placeholder="Select Dealer"
                                                            defaultValue={this.state.typeDrop}
                                                            onChange={this.handleTypeChange}
                                                            optionLabelProp="label"
                                                        >
                                                            {this.state.userDropdown && this.state.userDropdown.map(((item , i) => (
                                                <Option value={item.id} key={i} label={item.dealer_name}>{item.dealer_name} ({item.dealer_id})</Option>
                                            )))}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-md-12">
                                                {this.state.setEditId ?
                                                    <Form.Item initialValue={[moment(this.state.startDate), moment(this.state.endDate)]} name="date_range" labelCol={{ span: 24 }} label="Select Range" rules= {[{ required: true, message: 'This field is required!'}]}>
                                                        <RangePicker 
                                                            showTime={{
                                                                format: 'HH:mm',
                                                            }}
                                                            format="YYYY-MM-DD HH:mm"
                                                            defaultValue={[moment(this.state.startDate), moment(this.state.endDate)]}
                                                        />
                                                    </Form.Item>
                                                :
                                                <Form.Item name="date_range" labelCol={{ span: 24 }} label="Select Range" rules= {[{ required: true, message: 'This field is required!'}]}>
                                                        <RangePicker 
                                                            showTime={{
                                                                format: 'HH:mm',
                                                            }}
                                                            format="YYYY-MM-DD HH:mm"
                                                        />
                                                    </Form.Item>
                                                }
                                            </div>
                                            </div>
                                            <Form.Item>
                                                <div className="d-flex">
                                                    <Button type="primary" loading={this.state.imageLoading} htmlType="submit">
                                                        Upload
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
            </>
            
        )
    }
}

export default withRouter(OverlayTableColumn)
