import React from 'react'
import TableComp from './TableComp'
import { Input, Button, Space, Image ,Modal ,Pagination ,Spin ,Select ,PaginationProps, Upload, Switch, Popconfirm} from 'antd'
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words';
import { SearchOutlined, EditOutlined, UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { sendToEditor } from './../Actions/index';
import { connect } from 'react-redux'
import axios from 'axios'
import ImageViewer from './ImageViewer';
import { adminLink, allProcessedData, allProcessedImage, getImageSlider, resendRecord, singleProcessedData, uploadNewImage, allManualImage, removeRecentRecord } from '../configurations/urls';
import SliderImageViwer from './SliderImageViwer';
import ManualSliderImageViwer from './ManualSliderImageViwer';
import noImage from '../no_image.jpg'
import ImageViwerDrag from './ImageViwerDrag';
import { Store } from 'react-notifications-component';

const { Option } = Select;
const { confirm } = Modal;
class TableColumn extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        isModalVisible: false,
        visible: false,
        index: 0,
        source: '',
        orgImage: '',
        remImage: '',
        imgGroup: [],
        currentIndex: 0,
        isViewerOpen: false,
        currentImage: null,
        loading: false,
        dropdownUser: [],
        viewOpenInventory:false,
        sliderImageData:[],
        otherSource: '',
        nextRecord:'',
        perivousRecord:'',
        clientDropdown:'all',
        SliderImageId: "",
        currentPage: 1,
        removed_record: false,
        imageLoading: false,
        viewManualInventory:false,
        resultType: '',
        filters: {},
        addingRecent: false
    };
    fetchUsers = () => {
        const page = window.localStorage.getItem("currentInventoryPage")
        axios.get(adminLink)
            .then(res => {
                this.setState({ dropdownUser: res.data['all_users'] })
                let clientDrop =  window.localStorage.getItem("inventoryListDropdown")
                let dealer_exists = false;
                if (this.props.user.user_type != "Admin" && clientDrop != "all"){
                    if (clientDrop === null){
                        dealer_exists = false;
                    }else{
                    res.data['all_users'].map(dealer => {
                        if (clientDrop != null && dealer.dealer_id === clientDrop){
                            dealer_exists = true;
                            // clientDrop = res.data['all_users'][0].dealer_id;
                        }
                    })
                }
                if (!dealer_exists){
                    clientDrop = res.data['all_users'][0].dealer_id;
                }
                }
            this.setState({ clientDropdown: (!!clientDrop && clientDrop != null) ? clientDrop:"all"})
            if (this.state.clientDropdown == "all" || this.state.clientDropdown == null || !this.state.clientDropdown) {
                var url = allProcessedData;
                if (page > 1 && localStorage.getItem("is_scrool") =="true"){
                    url = `${allProcessedData}?page=${page}`
                }
                axios.get(url)
                    .then(res => {
                     
                        if(res.status==200 ){
                            this.setState({ source: this.dataSources(res.data['data']) })
                            this.setState({ otherSource: this.otherDataSources(res.data) })
                            this.setState({ currentPage: res.data['page'] })
                            if(window.location.pathname == "/inventory-list" && localStorage.getItem("is_scrool") =="true"){
                                window.scrollTo({ top: localStorage.getItem("scroll-postion"), behavior: 'smooth' });
                                window.localStorage.setItem('is_scrool', false)
                                // window.localStorage.setItem('currentInventoryPage', 1)
                            }
                         }
                         else{
                            this.setState({ source: ""})  
                            
                         }
    
                    })
                    .catch(err => {
                    
                    })
            }
            else {
                var url = `${singleProcessedData}${this.state.clientDropdown}/`;
                if (page > 1 && localStorage.getItem("is_scrool") =="true"){
                    url = `${singleProcessedData}${this.state.clientDropdown}/?page=${page}`
                }
                axios.get(url)
                    .then(res => {
                     if(res.status==200 ){
                        this.setState({ source: this.dataSources(res.data['data']) })
                        this.setState({ otherSource: this.otherDataSources(res.data) })
                        this.setState({ currentPage: res.data['page'] })
                        if(window.location.pathname == "/inventory-list" && localStorage.getItem("is_scrool") =="true"){
                            window.scrollTo({ top: localStorage.getItem("scroll-postion"), behavior: 'smooth' });
                            window.localStorage.setItem('is_scrool', false)
                            // window.localStorage.setItem('currentInventoryPage', 1)
                        }
                     }
                     else{
                        this.setState({ source: ""})  
                     }
                    }).catch(err => {
                     
                    })
            }
            })
            .catch(err => {
              
            })
    }
    componentDidMount() {
        this.setState({ source: this.props.dataSource })
        this.setState({ otherSource: this.props.otherDataSource })
        const fimg = []
        this.props.dataSource.forEach(d => {
            fimg.push({ "img": d.editted_image, type: 'rem', 'data': d })
        })
        this.setState({ imgGroup: fimg })
        if (window.location.pathname == "/inventory-list") {
            this.fetchUsers()
        }
    }
    objectsEqual = (o1, o2) =>
        typeof o1 === 'object' && Object.keys(o1).length > 0
            ? Object.keys(o1).length === Object.keys(o2).length
            && Object.keys(o1).every(p => this.objectsEqual(o1[p], o2[p]))
            : o1 === o2;
    componentDidUpdate(prevPros, prevState) {
        if (prevPros.otherDataSource.length !== this.props.otherDataSource.length){
            this.setState({ otherSource: this.props.otherDataSource  })
        }
        if (prevPros.dataSource.length !== this.props.dataSource.length) {
            this.setState({ source: this.props.dataSource })
            const fimg = []
            this.props.dataSource.forEach(d => {
                fimg.push({ "img": d.editted_image, type: 'rem', 'data': d })
            })
            this.setState({ imgGroup: fimg })
        }


        if (!this.props.loading && !this.state.loading) {
            
            const prevScrollPosition = window.localStorage.getItem('inventory-list-top')
            // if (prevScrollPosition) {
            //     window.scrollTo({left:0, top:parseInt(prevScrollPosition), behavior: 'smooth'});
            //     setTimeout(() => {
            //         window.localStorage.removeItem('inventory-list-top')
            //     }, 2000)
            //     // window.localStorage.removeItem('inventory-list-top')
            // }
        }
    }
    openImageViewer = (index, record) => {
        // if(window.location.pathname=="/inventory-list"){
            if(!!record.number_of_images){
            this.setState({ SliderImageId: record.id })
                
            this.setState({ viewOpenInventory: true })
            let clientDrop =  !!window.localStorage.getItem("inventoryListDropdown") ?window.localStorage.getItem("inventoryListDropdown"):"all"
            if(window.location.pathname == "/inventory-list"){
            axios.get(allProcessedImage +record.id+"/?dealerid="+clientDrop)
            .then(res => {
                this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
                this.setState({ nextRecord: !!res.data.next_record?res.data.next_record:"" })
                this.setState({ perivousRecord: !!res.data.previous_record?res.data.previous_record: "" })
               
            })
            .catch(err => {
               
            })
        }
        else{
            axios.get(allProcessedImage +record.id +"/?dealerid="+this.props.match.params.id)
            .then(res => {
                this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
                this.setState({ nextRecord: !!res.data.next_record?res.data.next_record:"" })
                this.setState({ perivousRecord: !!res.data.previous_record?res.data.previous_record: "" })
                this.setState({ removed_record: !!res.data.removed?res.data.removed: false })
               
            })
            .catch(err => {
               
            })  
        }
        }
    //     }
    //     else{
    //     if (!!record.editted_image) {
    //         this.setState({ currentIndex: index, isViewerOpen: true });
    //         this.updateImage(index)
    //     }
    // }
    
    };
    nextImageViewer=()=>{
        this.setState({ sliderImageData: [] })
        let clientDrop =  window.localStorage.getItem("inventoryListDropdown")
        if(window.location.pathname =="/inventory-list"){
        axios.get(allProcessedImage +this.state.nextRecord +"/?dealerid="+clientDrop)
        .then(res => {
            this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
            this.setState({ nextRecord: !!res.data.next_record?res.data.next_record:"" })
            this.setState({ perivousRecord: !!res.data.previous_record?res.data.previous_record: "" })
           
        })
        .catch(err => {
           
        })
    }
    else{
        axios.get(allProcessedImage +this.state.nextRecord +"/?dealerid="+this.props.match.params.id)
        .then(res => {
            this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
            this.setState({ nextRecord: !!res.data.next_record?res.data.next_record:"" })
            this.setState({ perivousRecord: !!res.data.previous_record?res.data.previous_record: "" })
           
        })
        .catch(err => {
           
        })
    }
    }
    perviousImageViewer=()=>{
        this.setState({ sliderImageData: [] })
        let clientDrop =  window.localStorage.getItem("inventoryListDropdown")
        if(window.location.pathname =="/inventory-list"){
        axios.get(allProcessedImage +this.state.perivousRecord +"/?dealerid="+clientDrop)
        .then(res => {
            this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
            this.setState({ nextRecord: !!res.data.next_record?res.data.next_record:"" })
            this.setState({ perivousRecord: !!res.data.previous_record?res.data.previous_record: "" })
           
        })
        .catch(err => {
           
        })
    }
    else{
        axios.get(allProcessedImage +this.state.perivousRecord +"/?dealerid="+this.props.match.params.id)
        .then(res => {
            this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
            this.setState({ nextRecord: !!res.data.next_record?res.data.next_record:"" })
            this.setState({ perivousRecord: !!res.data.previous_record?res.data.previous_record: "" })
           
        })
        .catch(err => {
           
        })
    }
    }
    closeImageViewer = () => {
        this.setState({ currentIndex: 0, currentImage: null, isViewerOpen: false })
        this.setState({viewOpenInventory: false})
        this.setState({ sliderImageData: [] })
        this.setState({ nextRecord: "" })
        this.setState({ perivousRecord: "" })
        // window.location.reload();
    }
    closeManualImageViewer = () => {
        this.setState({ currentIndex: 0, currentImage: null, isViewerOpen: false })
        this.setState({viewManualInventory: false})
        this.setState({ sliderImageData: [] })
        this.setState({ nextRecord: "" })
        this.setState({ perivousRecord: "" })
        // window.location.reload();
    }
    showMore(record) {
        for(let i in this.state.source){
            if(this.state.source[i].id==record.id){
            if( record.itemsToShow === 2 ){
                this.state.source[i].itemsToShow= Object.entries(record.other_info).length
                this.state.source[i].expanded= true
                this.forceUpdate();
            }
            else{
            
                this.state.source[i].itemsToShow=2
                this.state.source[i].expanded= false
                this.forceUpdate();
            }
            }
        }
        // record.itemsToShow === 3 ? (
        //   this.setState({ itemsToShow: this.state.cars.length, expanded: true })
        // ) : (
        //   this.setState({ itemsToShow: 3, expanded: false })
        // )
      }
    updateImage = (index) => {
        this.state.source.length > 0 && this.setState({ currentImage: this.state.imgGroup[index] })
    }
    changeFun = (val) => {
        const { currentIndex, imgGroup } = this.state

        if (val === 'prev') {
            if ((currentIndex - 1) < 0) return
            this.setState({ currentIndex: currentIndex - 1 })
            this.updateImage(currentIndex - 1)
        } else if (val === 'next') {
            if ((currentIndex + 1) > imgGroup.length) return
            this.setState({ currentIndex: currentIndex + 1 })
            this.updateImage(currentIndex + 1)
        }
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90, alignItems: 'center', display: 'inline-block' }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        // confirm();
        // this.setState({
        //     searchText: selectedKeys[0],
        //     searchedColumn: dataIndex,
        // });
        let filterObject = {}
        {Object.entries(this.state.filters).map(([key,value]) => (
            filterObject[key] = value
        ))}
        filterObject[dataIndex] = selectedKeys[0]
        this.setState({filters: filterObject})
        let url = '';
        if (this.state.clientDropdown == "all" || this.state.clientDropdown == null || !this.state.clientDropdown) {
            url = `${allProcessedData}?q=${JSON.stringify(filterObject)}`
        }else{
            url = `${singleProcessedData}${this.state.clientDropdown}/?q=${JSON.stringify(filterObject)}`
        }
        axios.get(url)
            .then(res => {
                if(res.status==200 ){
                    this.setState({ source: this.dataSources(res.data['data']) })
                    this.setState({ otherSource: this.otherDataSources(res.data) })
                    this.setState({ currentPage: res.data['page'] })
                 }
                 else{
                    this.setState({ source: ""})  
                    this.setState({ otherSource:{page:"", total:"", page_size:""} })
                 }
                 confirm();
                this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
            });
            })
            .catch(err => {
            
            })
    };

    handleReset = (clearFilters, dataIndex) => {
        const page = window.localStorage.getItem("currentInventoryPage")
        let filterObject = {}
        Object.entries(this.state.filters).map(([key,value]) => {
            if(key !== dataIndex){
                filterObject[key] = value
            }
        })
        this.setState({filters: filterObject})
        clearFilters();
        this.setState({ searchText: '' });
        let url = '';
        if (this.state.clientDropdown == "all" || this.state.clientDropdown == null || !this.state.clientDropdown) {
            if(Object.keys(filterObject).length > 0){
                url = `${allProcessedData}?q=${JSON.stringify(filterObject)}`
            }else{
                url = `${allProcessedData}?page=${page}`
            }
        }else{
            if(Object.keys(filterObject).length > 0){
                url = `${singleProcessedData}${this.state.clientDropdown}/?q=${JSON.stringify(filterObject)}`
            }else{
                url = `${singleProcessedData}${this.state.clientDropdown}/?page=${page}`
            }
        }
        axios.get(url)
            .then(res => {
                if(res.status==200 ){
                    this.setState({ source: this.dataSources(res.data['data']) })
                    this.setState({ otherSource: this.otherDataSources(res.data) })
                    this.setState({ currentPage: res.data['page'] })
                 }
                 else{
                    this.setState({ source: ""})  
                    this.setState({ otherSource:{page:"", total:"", page_size:""} })
                 }
            })
            .catch(err => {
            
            })
    };
    openSingle = (val) => {
        // window.localStorage.setItem('inventoryListDropdown', val)
        this.props.history.push(`/client/${val}`)
    }
    getBase64FromUrl = async (url) => {
        const data = await fetch(url);
        const blob = await data.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            }
        });
    }
    addDefaultSrc = (e) =>{
        e.target.src = noImage
        // e.target.style = 'boder: none; border-radius: 5px'
      }
      openManualViewer = (index, record) => {
            this.setState({ SliderImageId: record.id })
                
            this.setState({ viewManualInventory: true })
            let clientDrop =  !!window.localStorage.getItem("inventoryListDropdown") ?window.localStorage.getItem("inventoryListDropdown"):"all"
            axios.get(allManualImage +record.id+"/")
            .then(res => {
                this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
                this.setState({ resultType: res.data.result_type })
               
            })
            .catch(err => {
               
            })
        }
        openQRViewer = (index, record) => {
            this.setState({ SliderImageId: record.id })
                
            this.setState({ viewManualInventory: true })
            let clientDrop =  !!window.localStorage.getItem("inventoryListDropdown") ?window.localStorage.getItem("inventoryListDropdown"):"all"
            axios.get(allManualImage +record.id+"/?result_type=QR")
            .then(res => {
                this.setState({ sliderImageData: !!res.data.images?res.data.images:[] })
                this.setState({ resultType: res.data.result_type })
               
            })
            .catch(err => {
               
            })
        }
        openCollage = (id) => {
            window.location.pathname = "/collage/"+id
            localStorage.setItem("perviousRoute" , "/inventory-list")
        }
        removeRecent = (checked, id) => {
            let modal_txt = "Are you sure to remove updation required status?";
            if (checked){
                modal_txt = "Are you sure to add updation required status?"
            }
            confirm ({
                icon: <ExclamationCircleOutlined />,
                content: modal_txt,
                className: "confirm-active_modal",
                onOk: () => {
                    this.setState({ addingRecent: true})
                    axios.put(`${removeRecentRecord}${id}/`, { active: checked})
                        .then(res => {
                            var doc = document.documentElement;
                            const currentPosition = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
                            localStorage.setItem("scroll-postion" , window.scrollY)
                            window.localStorage.setItem('inventory-list-top', currentPosition)
                            window.localStorage.setItem("currentInventoryPage", this.state.currentPage);
                            window.localStorage.setItem("is_scrool" , "true")
                            this.setState({ addingRecent: false})
                            // this.setState(prevState => ({
                            //     source: prevState.source.map(
                            //         el => el.id === id? { ...el, recently_added: checked, edited_count: 0 }: el
                            //     )
                            // }))
                            Store.addNotification({
                                title: "Status changed successfully",
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 2500,
                                    onScreen: true
                                }
                            });
                            setTimeout(() => {
                                window.location.reload();
                            }, 2500)
                        })
                        .catch(err => {
                            this.setState({ addingRecent: false})
                            Store.addNotification({
                                 title: "There is something wrong",
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animate__animated", "animate__fadeIn"],
                                animationOut: ["animate__animated", "animate__fadeOut"],
                                dismiss: {
                                    duration: 2500,
                                    onScreen: true
                                }
                                
                            })
                        })
                }
            })
        };
        onResendToCsv = (a) => {
            this.setState({addingRecent: true})
            axios.post(`${resendRecord}${a.dealer_id}/${a.id}/`)
                .then(res => {
                    Store.addNotification({
                        title: "Record added successfully.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 2500,
                          onScreen: true
                        }
                      });
                    this.setState({addingRecent: false})
                }).catch(err => {
                    Store.addNotification({
                        title: "Something went wrong",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                          duration: 2500,
                          onScreen: true
                        }
                      });
                    this.setState({addingRecent: false})
                })
        }
    columns = [
        {
            title: 'Original Image',
            dataIndex: 'original_image',
            key: 'original_image',
            render: (image, record, index) => {
                return <>
               <div className="ant-image-sec">
                 <Image
                    preview={false}
                    width={80}
                    style={{ cursor: 'pointer', border: (record.marked_for_review == true ? '2px solid #FFD700':'none' )}}
                    src={!!record.original_image ? record.original_image : noImage} 
                    onError={this.addDefaultSrc}                   
                    
                >                    
                </Image>
                {!!record.number_of_images &&
                <a onClick={() => this.openImageViewer(index, record)}>VIEW MORE</a>}
                </div>
               
                </>

            }


        },
        {
            title: 'Removed Image',
            dataIndex: 'editted_image',
            key: 'editted_image',
            render: (image, record, index) =><>
            {!!record.overlay_image &&
                <div className='overlay-image'>
                <Image
                    preview={false}
                    width={80}
                    src={!!record.editted_image ? record.overlay_url : noImage} 
                    onError={this.addDefaultSrc}
                    style={{ margin: '2px', cursor: 'pointer', border: (record.edited_count > 0  && record.editted_image && record.all_updated !== undefined) ?  record.all_updated == true ? '2px solid #7FFF00' : '2px solid #FF0000' :  'none' }}                  
                    
                >                    
                </Image>
                </div>
                }
                <div className="ant-image-sec">
                <Image preview={false} width={80} style={{ margin: '2px', cursor: 'pointer', border: (record.edited_count > 0  && record.editted_image && record.all_updated !== undefined) ?  record.all_updated == true ? '2px solid #7FFF00' : '2px solid #FF0000' :  'none' }}
                    src={!!record.editted_image ? this.props.updateImage === record.editted_image ? `${record.editted_image}?${Date.now()}` : record.editted_image : noImage}  alt="img" onClick={() => this.openImageViewer(index, record)} />
                </div>
                </>
        },
        {
            title: 'Dealer ID',
            dataIndex: 'owner',
            dataIndex: 'dealer_id',
            className: 'narrow-row',
            key: 'owner',
            ...this.getColumnSearchProps('owner'),
            ...this.getColumnSearchProps('dealer_id'),

            render: text => this.props.user && this.props.user.user.user_type != "Dealer" ? <div style={{ cursor: 'pointer', color: 'blue' }} onClick={() => this.openSingle(text)}>{text}</div> : text
        },
        {
            title: 'Dealer Name',
            dataIndex: 'dealer_name',
            key: 'dealer_name',
            ...this.getColumnSearchProps('dealer_name'),
            sorter: (a, b) => a.DealerName - b.DealerName,
            sortDirections: ['descend'],
        },
        {
            title: 'Stock Number',
            dataIndex: 'stock_number',
            key: 'stock_number',
            ...this.getColumnSearchProps('stock_number'),
            sorter: (a, b) => a.StockNumber - b.StockNumber,
            sortDirections: ['descend'],
        },
        {
            title: 'VIN',
            dataIndex: 'vin',
            key: 'vin',
            ...this.getColumnSearchProps('vin'),
            sorter: (a, b) => a.VIN - b.VIN,
            sortDirections: ['descend'],
        },
        {
            title: 'Last Lot Service',
            dataIndex: 'last_lot_service',
            key: 'last_lot_service',
            sorter: (a, b) => a.LastLotService - b.LastLotService,
            sortDirections: ['descend'],
        },
        {
            title: 'No. of Images',
            dataIndex: 'number_of_images',
            className: 'narrow-row',
            key: 'number_of_images',
            sorter: (a,b) => a.stock_number - b.stock_number,
            sortDirections: ['descend'],
            // sorter: (a, b) => a.stock_number - b.stock_number
        },
        {
            title: 'Lot Location',
            dataIndex: 'lot_location',
            key: 'lot_location',
            className: 'narrow-row'
        },
        {
            title: 'Vehicle Type',
            dataIndex: 'vehicle_type',
            key: 'vehicle_type',
            className: 'narrow-row',
            ...this.getColumnSearchProps('vehicle_type'),
        },
        {
            title: 'Make',
            dataIndex: 'car_make',
            key: 'car_make',
            ...this.getColumnSearchProps('car_make'),
        },
        {
            title: 'Model',
            dataIndex: 'car_model',
            key: 'car_model',
            ...this.getColumnSearchProps('car_model'),
        },
        {
            title: 'Other Fields',
            className: 'otherfields_header',
            // dataIndex: 'operation',
            key: 'operation',
            render: (record) => 
            <div className='otherfields_detailswarp'>
            {record.other_info&&
            <>
            <div >
                 {Object.entries(record.other_info).slice(0, record.itemsToShow).map(([key,value], index) =>(<div key={index}><strong>{key}</strong> : {value} </div>)) } 
                
            </div>
            <div className='show_moreless-wrap'>
            <a  onClick={()=>this.showMore(record)}>
                {record.expanded ? (
                    <span>Show less</span>
                ) : (
                    <span>Show more</span>
                )}
            </a>
            </div>
                    
        </>
        }
            </div>
        },
        {

            title: 'Resend',
            key: 'resendoperation',
            fixed: 'right',
            width: 150,
            align: 'center',
            render: (record) => {
                return <div className='d-flex align-items-center action_wrap'>
                    {!!record.can_resend_record ?
                        <Popconfirm className='send-popup' title="Are you sure you want to add URL(s) in CSV" onConfirm={() => this.onResendToCsv(record)} style={{ cursor: 'pointer' }} >
                            <Button type="primary" size="small">Resend to CSV</Button></Popconfirm>:"N/A"}
                </div>
            }
        },
        {

            title: 'Recent',
            key: 'recentoperation',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (record) => {
                return <>
            <Switch size="small" checked={record.recently_added} onChange={(checked) => this.removeRecent(checked, record.id)} />
            </>
            }
        },
    //     {...this.props.user && this.props.user.user.user_type != "Dealer" ?
    //     {

    //         title: 'Manual Added',
    //         key: 'manualoperation',
    //         fixed: 'right',
    //         width: 150,
    //         align: 'center',
    //         className: 'manual-added-inventory',
    //         render: (a, index) =>
    //         <>
    //         {a.manually_added ?
    //         <div className='manually_added-wrap'>
    //             <Button className="mb-2" size="small" onClick={() => this.openManualViewer(index, a)}>
    //                 <span>
    //                     View Collage
    //                 </span>
    //             </Button>
    //         </div>
    //         :null}
    //         <div className='manually_added-wrap'>
    //             <Button className="mb-2" size="small" onClick={() => this.openCollage(a.id)}>
    //                 <span>
    //                     Create Collage
    //                 </span>
    //             </Button>
    //         </div>
    //         {a.qr_code_added ?
    //         <div className='manually_added-wrap'>
    //             <Button className="mb-2" size="small" onClick={() => this.openQRViewer(index, a)}>
    //                 <span>
    //                     QR Code
    //                 </span>
    //             </Button>
    //         </div>
    //         :null}
    //         </>
    //     }
    //     :
    //     {

    //         title: 'Manual Added',
    //         key: 'manualoperation',
    //         fixed: 'right',
    //         hidden: true,
    //         width: 150,
    //         align: 'center',
    //         className: 'manual-added-inventory',
    //         render: (a, index) =>
    //         <>
    //         {a.manually_added ?
    //         <div className='manually_added-wrap'>
    //             <Button className="mb-2" size="small" onClick={() => this.openManualViewer(index, a)}>
    //                 <span>
    //                     View Collage
    //                 </span>
    //             </Button>
    //         </div>
    //         :null}
    //         <div className='manually_added-wrap'>
    //             <Button className="mb-2" size="small" onClick={() => this.openCollage(a.id)}>
    //                 <span>
    //                     Create Collage
    //                 </span>
    //             </Button>
    //         </div>
    //         {a.qr_code_added ?
    //         <div className='manually_added-wrap'>
    //             <Button className="mb-2" size="small" onClick={() => this.openQRViewer(index, a)}>
    //                 <span>
    //                     QR Code
    //                 </span>
    //             </Button>
    //         </div>
    //         :null}
    //         </>
    //     }
    // },
        {

            title: 'Action',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (a) => <><div style={{ cursor: 'pointer', display: 'inline-block' }} onClick={() => {

                var doc = document.documentElement;
                const currentPosition = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
                localStorage.setItem("scroll-postion" , window.scrollY)
                window.localStorage.setItem('inventory-list-top', currentPosition)
                window.localStorage.setItem("currentInventoryPage", this.state.currentPage);
                this.setState({ isModalVisible: true, originalImage: a.original_image, removedImage: a.processed_image, loading: true });
                this.openEditor(a)

            }}>
                <EditOutlined />

            </div>
            {this.props.user && this.props.user.user.user_type != "Dealer" ?
            <div className="ml-3" style={{ cursor: 'pointer', display: 'inline-block' }}>
            <Upload
              customRequest={(options) => this.uploadImage(options, a.id, a.owner)}
              maxCount={1}
              showUploadList={false}
              accept={'.jpeg,.jpg'}
            ><UploadOutlined />
            </Upload>
            </div>
            :null}
            </>
        }
    ].filter(item => !item.hidden);;
    handleCancel = () => {
        this.setState({ isModalVisible: true })
    };
    showModal = () => {
        this.setState({ isModalVisible: true })
    };
    handleTriggle = () => {
        this.setState(prevState => ({ visible: !prevState.visible }))
    }
    openEditor = async (a) => {
    
        let image = await axios.get(getImageSlider + a.image_id + "/");
        let orignal = image.data['original_image'];
       
        let processed = image.data['processed_image'];
        var doc = document.documentElement;
        const currentPosition = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0)
        window.localStorage.setItem('inventory-list-top', currentPosition)
        window.localStorage.setItem("current_image_id", image.data['image_order']);
        window.localStorage.setItem("current_csv_data_id", image.data['csv_data_id']);
  
        this.props.sendToEditor({ 
            orignalImage: `${orignal}`, 
        removedImage: `${processed}`, 
        link: this.props.location.pathname, 
        imgName:
        a.editted_image.split("/")[a.editted_image.split("/").length - 1],
        dealerId: a.owner ,
        original_image: a.original_image,
        images_edited: a.images_edited,
        image_id: a.image_id,
        csv_id: image.data['csv_data_id'],
        current_id: image.data['image_order'],
        original_url: a.link,
        stock_number: a.stock_number
    });
    localStorage.setItem("pervious_route" , this.props.location.pathname)
    this.props.history.push('/editor')
    }
    handleChange = (value) => {
        this.setState({ otherSource: "" })
        window.localStorage.setItem('inventoryListDropdown', value)
        // window.localStorage.setItem('currentInventoryPage', 1)
        this.setState({ clientDropdown: value })
       
        if (value == "all") {
            axios.get(allProcessedData)
                .then(res => {
                 
                    if(res.status==200 ){
                        this.setState({ source: this.dataSources(res.data['data']) })
                        this.setState({ otherSource: this.otherDataSources(res.data) })
                        this.setState({ currentPage: res.data['page'] })
                     }
                     else{
                        this.setState({ source: ""})  
                       
                     }

                })
                .catch(err => {
                
                })
        }
        else {
            axios.get(`${singleProcessedData}${value}/`)
                .then(res => {
                 if(res.status==200 ){
                    this.setState({ source: this.dataSources(res.data['data']) })
                    this.setState({ otherSource: this.otherDataSources(res.data) })
                    this.setState({ currentPage: res.data['page'] })
                 }
                 else{
                    this.setState({ source: ""})  
                 }
                }).catch(err => {
                 
                })
        }
    };
    otherDataSources = (data) => {
        let arr = {page:data['page'], total:data['total'], page_size:data['page_size']}
        return arr
    }
    handlepagination = (page) => {
        if(window.location.pathname == "/inventory-list"){
        if (this.state.clientDropdown == "all" || this.state.clientDropdown == null || !this.state.clientDropdown) {
            axios.get(`${allProcessedData}?page=${page}`)
                .then(res => {
                    // window.localStorage.setItem('currentInventoryPage', page)
                    if(res.status==200 ){
                        this.setState({ source: this.dataSources(res.data['data']) })
                        this.setState({ otherSource: this.otherDataSources(res.data) })
                        this.setState({ currentPage: res.data['page'] })
                     }
                     else{
                        this.setState({ source: ""})  
                        this.setState({ otherSource:{page:"", total:"", page_size:""} })
                     }

                })
                .catch(err => {
                
                })
        }
        else {
            axios.get(`${singleProcessedData}${this.state.clientDropdown}/?page=${page}`)
                .then(res => {
                    // window.localStorage.setItem('currentInventoryPage', page)
                 if(res.status==200 ){
                    this.setState({ source: this.dataSources(res.data['data']) })
                    this.setState({ otherSource: this.otherDataSources(res.data) })
                    this.setState({ currentPage: res.data['page'] })
                 }
                 else{
                    this.setState({ source: ""})  
                    this.setState({ otherSource:{page:0, total:0, page_size:0} })
                 }
                }).catch(err => {
                 
                })
        }
    
        }
        else{
            // if (this.props.user.is_admin === false) {
            //     axios.get(`${singleProcessedData}${this.props.user.dealer_id}/`)
            //         .then(res => {
            //             this.setState({ source: this.dataSources(res.data['data']) })
            //             this.setState({ otherSource: this.otherDataSources(res.data) })
                    
            //         }).catch(err => {
                    
            //         })
            // } else {
            
                    axios.get(`${singleProcessedData}${this.props.id}/?page=${page}`)
                        .then(res => {
                            if(res.status == 200){
                            this.setState({ source: this.dataSources(res.data['data']) })
                            this.setState({ otherSource: this.otherDataSources(res.data) })
                            }
                            else{
                                this.setState({ source: ""})  
                                this.setState({ otherSource:{page:0, total:0, page_size:0} })
                            }
                        }).catch(err => {
                            
                        })
            // }
        }
    };
     dataSources = (data) => {
        let arr = []
        data.length > 0 && data.forEach((d, i) => {
            if (d.images) {
                const tag = '?v=' + Date.now()
                d.images.forEach(img => {
              
                    arr.push({ ...d, 'images': '', 'editted_image': !!img.editted_image?`${img.editted_image}${tag}`:"", 'original_image': !!img.original_image?`${img.original_image}${tag}`:"", 'processed_image': !!img.processed_image?`${img.processed_image}${tag}`:"" ,'image_id':!!img.image_id?img.image_id:"" ,'images_edited':!!img.images_edited?img.images_edited:"",'itemsToShow': 2,
                    'expanded': false,'overlay_image': img.overlay_image, 'overlay_url': !!img.overlay_url?`${img.overlay_url}`:"",})
                })
            } else {
                arr.push({...d,'itemsToShow': 2,
                'expanded': false})
            }
        })
        return arr
    };
    // To upload new image
    convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  uploadImage = async (options, id, dealer_id) => {
    this.setState({ imageLoading: true}) 
    const { file } = options;
    let b64 = await this.convertToBase64(file);
    // b64 = b64.replace("data:image/png;base64,", "");
    b64 = b64.replace(/^data:image\/[a-z]+;base64,/, "");
    axios
      .post(
        `${uploadNewImage}${dealer_id}/${id}/`,
        {
          image_data: b64
        }
      )
      .then((res) => {
        this.setState({ imageLoading: false})
        window.location.reload()
      })
      .catch((err) => {
        this.setState({ imageLoading: false})
        Store.addNotification({
            title: "There is something wrong",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: true
            }
        })
      });
  };
  
    render() {
        return (
            <div>
                { !!this.state.imageLoading &&
                    <div className='loader'>
                        <Spin />
                        <h3 className="ml-3 text-white" >Uploading Image Please Wait...</h3>
                    </div>
                }
                { !!this.state.addingRecent &&
                    <div className='loader'>
                        <Spin />
                        <h3 className="ml-3 text-white" >Processing Record Please Wait...</h3>
                    </div>
                }
                {window.location.pathname == "/inventory-list" &&
                    <Select className='inventory-select invertry-list' value={this.state.clientDropdown}  onChange={this.handleChange} >
                        <Option value="all">All</Option>
                        {this.state.dropdownUser && this.state.dropdownUser.map(((item , i) => (
                            <Option value={item.dealer_id} key={i}>{item.dealer_name} ({item.dealer_id})</Option>
                        )))}
                    </Select>}
                    
                {(this.state.isViewerOpen && this.state.currentImage !== null && window.location.pathname != "/inventory-list") && (
                    <ImageViewer
                        current={this.state.currentImage}
                        changeFun={this.changeFun}
                        onClose={this.closeImageViewer}
                        link={"/inventory-list"}
                        updateImage={this.props.updateImage}
                        setUpdateImage={this.props.setUpdateImage}
                        setUpdateData={this.props.setUpdateData}
                    />
                )}
                {this.state.viewOpenInventory&&
                <SliderImageViwer  onClose={this.closeImageViewer}
                 nextImageViewer={this.nextImageViewer} 
                 perviousImageViewer={this.perviousImageViewer} 
                 link={"/inventory-list"} 
                 sliderImageData={this.state.sliderImageData}
                 nextRecord={this.state.nextRecord}
                 perivousRecord={this.state.perivousRecord}
                 SliderImageId={this.state.SliderImageId}
                 currentPage={this.state.currentPage}
                 removed_record={this.state.removed_record}/>
    }
                {this.state.viewManualInventory&&
                <ManualSliderImageViwer  onClose={this.closeManualImageViewer}
                //  nextImageViewer={this.nextImageViewer} 
                //  perviousImageViewer={this.perviousImageViewer} 
                //  link={"/inventory-list"} 
                 sliderImageData={this.state.sliderImageData}
                //  nextRecord={this.state.nextRecord}
                //  perivousRecord={this.state.perivousRecord}
                 SliderImageId={this.state.SliderImageId}
                 resultType={this.state.resultType}
                //  currentPage={this.state.currentPage}
                //  removed_record={this.state.removed_record}
                />
    }
                {this.state.loading ? <div className="container">
                    <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
                        <Spin />
                        <h3 className="ml-3">Opening Editor please wait...</h3>
                    </div>

                </div> :
                    <TableComp columns={this.columns} dataSource={this.state.source} loading={this.props.loading} />
                }
                {(this.state.otherSource) && (
                <div className="pagination-container">
                    <Pagination current={this.state.otherSource.page} showSizeChanger={false} onChange={this.handlepagination} pageSize={20} defaultPageSize={20} total={this.state.otherSource.total} />
                </div> 
                )}
         
            </div>
        )
    }
}

export default withRouter(connect(null, { sendToEditor })(TableColumn))
