import React, { useState, useEffect } from 'react'
import { Select, Button } from 'antd'
import { adminLink, convertImage, markImages, getInventoryReview } from '../configurations/urls';
import axios from 'axios';
import { Spin, Image, Modal, Form, Input, Checkbox } from "antd";
import TableComp from './TableComp';
import noImage from '../no_image.jpg'
import { Pagination } from 'antd'
import { triggerBase64Download } from "react-base64-downloader";
import { Store } from 'react-notifications-component';
import { saveAs } from 'file-saver';
import InfiniteScroll from "react-infinite-scroll-component";
const { Search } = Input;

const { Option } = Select;
const MarkImages = (props) => {
    const [userDropdown, setUserDropdown] = useState([]);
    const [clientDrop, setClientDrop] =  useState();
    const [imageLoading, setImageLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [tbData,setTbData]=useState([]);
    const [otherSource, setOtherSource]=useState([]);
    const [currentId, setCurrentId] = useState();
    const [hasMore, setHasMore] = useState(true);
    const [pendingFile, setPendingFiles] = useState(false);
    const [searchString, setSearchString] =  useState('');

    const fetchUsers = () => {
        axios.get(adminLink)
            .then(res => {
                const storedClientDrop = window.localStorage.getItem("markImageDropdown");
                let dealer_exists = false;
                res.data['all_users'].map(dealer => {
                    if (storedClientDrop != null && dealer.dealer_id === storedClientDrop){
                        dealer_exists = true;
                    }
                });
                if (storedClientDrop === null || !dealer_exists){
                    setClientDrop(res.data['all_users'][0].dealer_id)
                }
                setUserDropdown(res.data['all_users'])
            })
            .catch(err => {
               
            })
    }

    const fetchInventoryImages = () => {
        if (clientDrop){
            const url = `${getInventoryReview}?dealer=${clientDrop}&page=${page}&page_size=10&q=${searchString}`;
            axios.get(`${url}`)
            .then(res => {
                if(res.status==200 ){
                    // setTbData(inventoryDataSource(res.data['data']))
                    setTbData([...tbData, ...inventoryDataSource(res.data['data'])])
                    setPendingFiles(res.data['pending_file'])
                    setPage(page+1);
                    setHasMore(true)
                if (!res.data['links'].next){
                    setHasMore(false)
                }
                setImageLoading(false)
            }else{
                setHasMore(false)
                setImageLoading(false)
            }
            })
            .catch(err => {
                setImageLoading(false)
            })
        }
    }

    useEffect(() => {
        setClientDrop(window.localStorage.getItem("markImageDropdown"))
        fetchUsers();
    }, [])

    useEffect(() => {
        fetchInventoryImages()
    }, [clientDrop])

    useEffect(() => {
        fetchInventoryImages()
    }, [searchString])
    
    const handleChange = (value) => {
        setTbData([])
        setOtherSource([])
        setPage(1)
        setClientDrop(value)
        window.localStorage.setItem('markImageDropdown', value)
    };

    const inventoryDataSource = (data) => {
        let arr = []
        data.length > 0 && data.forEach((d, i) => {
         
            if (d) {
                arr.push({ origionalImage: d.original_image_url, optimizedImage:d.ai_optimized_url,edittedImage:d.editted_image_url,
                    imageName:d.dealer_id+'_'+d.stock_number+'_'+d.id.toString()+'.jpg', key:i, bgRemoved:d.bg_removed_image_url,
                    stockNumber:d.stock_number, imageId:d.id, markedForRework:d.marked_for_rework, imageUpdated: d.image_updated,
                    downloadImageName:d.dealer_id+'_'+d.stock_number+'_'+d.id.toString(), recentlyAdded: d.recently_added,
                    manuallyAdded: d.manually_added, lastLotService: d.last_lot_service, vehicleType: d.vehicle_type, isSelected: false,
                    overlayAdded: d.overlay_added, overlayImage: d.overlay_image_url, imageEditedEarlier: d.image_edited_earlier
                })
            }
        })
        return arr
    }

    const downloadJPEG = (record) => {
        setImageLoading(true)
        let stokeNumber = record.downloadImageName;
        let img_url = record.origionalImage;
        axios.get(`${convertImage}?image=${img_url}`)
            .then(res => {
                let b64 = "data:image/jpg;base64,"+res.data['data']
                triggerBase64Download(b64, stokeNumber);
                setImageLoading(false)
            })
            .catch(err => {
                setImageLoading(false)
                Store.addNotification({
                    title: "Something went wrong. Please try again",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2500,
                      onScreen: true
                    }
                });
            })
    }

    const downloadPNG = (record) => {
        setImageLoading(true)
        let stokeNumber = record.downloadImageName;
        axios.get(`${convertImage}?image=${record.bgRemoved}`)
            .then(res => {
                let b64 = "data:image/png;base64,"+res.data['data']
                triggerBase64Download(b64, stokeNumber);
                setImageLoading(false)
            })
            .catch(err => {
                setImageLoading(false)
                Store.addNotification({
                    title: "Something went wrong. Please try again",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2500,
                      onScreen: true
                    }
                });
            })
    }

    const markForRework = (evt, record) => {
        setImageLoading(true)
        let imageId = record.imageId;
        evt.currentTarget.disabled = true;
        axios.post(`${markImages}${imageId}/`)
            .then(res => {
                Store.addNotification({
                    title: "Image marked for review",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2500,
                        onScreen: true
                    }
                });
                setImageLoading(false)
            })
            .catch(err => {
                Store.addNotification({
                    title: "Something went wrong",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2500,
                      onScreen: true
                    }
                });
                setImageLoading(false)
            })
    }
    const handleImageChange = (e, id) => {
        const nextImages = tbData.map((image) =>
            image.imageId === id ? { ...image, isSelected: !image.isSelected } : image
        );
        setTbData(nextImages);
    }

    const columns = [
        {
            title: '',
            key: 'selection',
            width: '2%',
            render: (image, record, index) => {
                return <>
                <Checkbox checked={record.isSelected} onChange={(e) => handleImageChange(e, record.imageId)}></Checkbox>     
                </>

            }
        },{
            title: 'Original',
            dataIndex: 'origionalImage',
            key: 'origionalImage',
            width: '20%',
            align: 'center',
            render: (image, record, index) => {
                return <>
                <div className='ant-table-mark'>
                    <Image
                        preview={!!record.origionalImage ? true : false}
                        src={!!record.origionalImage ? record.origionalImage : noImage}                 
                    
                    >                    
                    </Image>
                </div>
                </>

            }
        },{
            title: 'AI Optimized',
            dataIndex: 'optimizedImage',
            key: 'optimizedImage',
            width: '20%',
            align: 'center',
            render: (image, record, index) => {
                return <>
                <div className='ant-table-mark'>
                    <Image
                        preview={!!record.optimizedImage ? true : false}
                        src={!!record.optimizedImage ? record.optimizedImage : noImage}                  
                    
                    >                    
                    </Image>
                </div>
                </>
            }
        },{
            title: 'Manually Edited',
            dataIndex: 'edittedImage',
            key: 'edittedImage',
            width: '20%',
            align: 'center',
            render: (image, record, index) => {
                return <>
                <div className='ant-table-mark'>
                    <Image
                        preview={!!record.edittedImage && record.imageEditedEarlier ? true : false}
                        src={!!record.overlayAdded && record.overlayImage ? record.overlayImage : !!record.edittedImage && record.imageEditedEarlier ? record.edittedImage : noImage}                  
                    
                    >                    
                    </Image>
                </div>
                </>
            }
        },{
            title: 'Action',
            key: 'operation',
            render: (image, record, index) => {
                return <>
                <div className='mark-images-button'>
                    <Button disabled={pendingFile} type="primary" className="mb-3" onClick={()=>downloadJPEG(record)}>Download JPG</Button>
                    <Button disabled={pendingFile} type="primary" className="mt-3 mb-3" onClick={()=>downloadPNG(record)}>Download PNG</Button>
                    {!record.markedForRework ?
                        <Button type="primary" className="mt-3 mb-4" onClick={(evt)=>markForRework(evt,record)}>Flag For Review</Button>
                    :
                        <Button type="primary" className="mt-3 mb-4" disabled>Flag For Review</Button>
                    }
                </div>      
                </>

            }
        },{
            title: '',
            key: 'details',
            render: (image, record, index) => {
                return <>
                <div className='mark-images-details'>
                <h6><strong>{record.imageName}</strong></h6>
                <p>Stock # : {record.stockNumber}</p>
                <p>Last Lot Service : {record.lastLotService}</p>
                <p>Vehicle Type : {record.vehicleType}</p>
                </div>      
                </>

            }
        }
    ];

    var today = new Date(),
    date = today.getFullYear() + '_' + (today.getMonth() + 1) + '_' + today.getDate();
    var JSZip = require("jszip");
    var zip = new JSZip();
    const handleJPGDownload = () => {
        setImageLoading(true);
        var count = 0;
        var zipFilename = 'images_' + date +'.zip';
        const imagesAsArray = []
        tbData.forEach(d => {
            if (d.isSelected){
                imagesAsArray.push({'url': d.origionalImage, 'name': d.imageName})
            }
        })
        if (imagesAsArray.length > 0){
            imagesAsArray.forEach(function(url){
                var filename = url.name;
                axios.get(`${convertImage}?image=${url.url}`)
                    .then(res => {
                        zip.file(filename, res.data['data'], {base64:true});
                        count++;
                        if (count == imagesAsArray.length) {
                            zip.generateAsync({type:'blob'}).then(function(content) {
                                saveAs(content, zipFilename);
                            });
                            setImageLoading(false);
                        }
                    })
                    .catch(err => {
                        setImageLoading(false);
                    })
            });
        }else{
            Store.addNotification({
                title: "Please select images to download",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
            setImageLoading(false);
        }
    }

    const handlePNGDownload = () => {
        setImageLoading(true);
        var count = 0;
        var zipFilename = 'images_' + date +'.zip';
        const imagesAsArray = []
        tbData.forEach(d => {
            if (d.isSelected){
                var fileName = d.imageName.substr(0, d.imageName.lastIndexOf(".")) + ".png";
                imagesAsArray.push({'url': d.bgRemoved, 'name': fileName})
            }
        })
        if (imagesAsArray.length > 0){
            imagesAsArray.forEach(function(url){
                var filename = url.name;
                axios.get(`${convertImage}?image=${url.url}`)
                    .then(res => {
                        zip.file(filename, res.data['data'], {base64:true});
                        count++;
                        if (count == imagesAsArray.length) {
                            zip.generateAsync({type:'blob'}).then(function(content) {
                                saveAs(content, zipFilename);
                            });
                            setImageLoading(false);
                        }
                    })
                    .catch(err => {
                        setImageLoading(false);
                    })
            });
        }else{
            Store.addNotification({
                title: "Please select images to download",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
            setImageLoading(false);
        }

    }
    const onSearch = (value) => {
        setTbData([])
        setOtherSource([])
        setPage(1)
        setSearchString(value)
        setImageLoading(true)
    };

    return (
        <div className='container review-images-container'>
            { !!imageLoading &&
                <div className='loader'>
                    <Spin />
                    <h3 className="ml-3 text-white" >Please Wait...</h3>
                </div>
            }
            <div className='row'>
                <div className='col-md-6'>
                <Button disabled={pendingFile} style={{ display:'inline-block'}} type="primary" className="mb-3 ml-3" onClick={handlePNGDownload}>Bulk PNG Download</Button>
            <Button disabled={pendingFile} style={{ display:'inline-block'}} type="primary" className="mb-3 ml-3" onClick={handleJPGDownload}>Bulk JPG Download</Button>
            <Search
                    placeholder="Search text here"
                    onSearch={onSearch}
                    allowClear
                    style={{
                        width: 200,
                        marginLeft: "15px"
                    }}
                    enterButton
                />
                </div>
                <div className='col-md-6'>
            <Select className='inventory-select invertry-list' value={clientDrop} onChange={handleChange}>
                {userDropdown && userDropdown.map(((item , i) => (
                    <Option value={item.dealer_id} key={i}>{item.dealer_name} ({item.dealer_id})</Option>
                )))}
            </Select>
            </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                <InfiniteScroll
                dataLength={tbData.length}
                next={() => {
                    fetchInventoryImages();
                }}
                hasMore={hasMore}
                loader={
                    <div style={{textAlign:'center'}}>
                        <span className='mt-4' style={{color:'rgba(0, 0, 0, 0.25)', display:'block'}}>Scroll down to load more...</span>
                    </div>
                }
                endMessage={
                    <div style={{ textAlign: "center" }}>
                        <span className='mt-4' style={{color:'rgba(0, 0, 0, 0.25)', display:'block'}}>End of results</span>
                    </div>
                }
            >
            <TableComp columns={columns} dataSource={tbData} />
            </InfiniteScroll>
            </div>
            </div>
        </div>
    )
}

export default MarkImages
