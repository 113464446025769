import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { allProcessedImage, allReorderImage } from '../configurations/urls'
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { Spin, Badge, Space } from "antd";

const ImageViwerDrag = () => {
  let { id } = useParams()
  const [sliderImageData, setSliderImageData] = useState([])
  const [DragImageLoading, setImageLoading] = useState(false)
  const [requiredOrder, setRequiredOrder] = useState([])
  const [manualOrder, setManualOrder] = useState([])
  useEffect(() => {
    if (!!id) {
      axios.get(allProcessedImage + id + "/")
        .then(res => {
          setSliderImageData(!!res.data.images ? res.data.images : [])
          setRequiredOrder(res.data.required_images_order)
          setManualOrder(res.data.manual_added_order)
        })
        .catch(err => {

        })
    }
  }, [id])
  const itemRenderer = (item, index) => {
    return (
      <div className="reorder-imgwrap">
        {/* <Badge count={index+1}> */}
        <img src={item.original_image} />
        {/* </Badge> */}
      </div>
    );
  }
  const handleRLDDChange = (reorderedItems) => {
    setSliderImageData(reorderedItems)
  }
  const handleSave = () => {
    setImageLoading(true)
    axios.post(allReorderImage + id + "/", { reorder_data: sliderImageData })
      .then(res => {
        setImageLoading(false)
      })
      .catch(err => {
        setImageLoading(false)

      })

  }
  const handleBack = () => {
    window.location.pathname = !!window.localStorage.getItem("perviousRoute") ? window.localStorage.getItem("perviousRoute") : "/inventory-list"
  }
  return (
    <div>
      <p>Sequence order of processing : [{requiredOrder.join(", ")}] (this sequence ignores manually added images)</p>
      {!!manualOrder.length > 0 &&
      <p>Manually added images : [{manualOrder.join(", ")}]</p>
      }
      {!!DragImageLoading &&
        <div className='loader'>
          <Spin />
          <h3 className="ml-3 text-white" >Saving Images Please wait...</h3>
        </div>}
      {!!sliderImageData.length &&
        <>
          <RLDD
            cssClasses="reorder-list"
            items={sliderImageData}
            itemRenderer={itemRenderer}
            onChange={handleRLDDChange}
          />
          <button onClick={handleSave} className="btn btn-primary mt-3">Save</button>
        </>
      }
      <button onClick={handleBack} className="btn btn-secondary mt-3 ml-2">Back</button>

    </div>
  )
}
export default ImageViwerDrag