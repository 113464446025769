import { useState, useEffect } from 'react'
import TableColumn from './TableColumn';
import axios from 'axios'
import { singleProcessedData } from '../configurations/urls';


const ListUsers = ({ user, id }) => {
    const [data, setData] = useState([])
    const [otherData, setOtherData] = useState([])
    useEffect(() => {
      
        if (user.user_type === "Dealer") {
            axios.get(`${singleProcessedData}${user.dealer_id}/`)
                .then(res => {
                    setData(res.data['data'])
                    setOtherData(res.data)
                }).catch(err => {
                  
                })
        } else {
          
                axios.get(`${singleProcessedData}${id}/`)
                    .then(res => {
                        setData(res.data['data'])
                        setOtherData(res.data)
                    }).catch(err => {
                        
                    })
        }

    }, [])

    const dataSource = () => {
        let arr = []
        if(!!data && data.length > 0 ){
        data.forEach((d, i) => {
            if (d.images) {
            
                d.images.forEach(img => {
                    arr.push({ ...d, 'images': '', 'editted_image': `${img.editted_image}`, 'original_image': `${img.original_image}`, 'processed_image': `${img.processed_image}` ,'image_id':!!img.image_id?img.image_id:"" ,'images_edited':!!img.images_edited?img.images_edited:"" ,'itemsToShow': 2,
                    'expanded': false})
                })
            } else {
              
                arr.push({...d,'itemsToShow': 2,
                'expanded': false})
            }
        })
    }
        return arr
       
    }
    const otherDataSource = () => {
        let arr = ''
        if (otherData['page']){
            arr = {page:otherData['page'], total:otherData['total'], pageSize:otherData['page_size']}
        }
        return arr
    }

    return (
        <>
             <TableColumn dataSource={dataSource()} otherDataSource={otherDataSource()} id={id} />

        </>
    )
}



export default ListUsers
