import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createCollage, baseURLWithoutSlash } from '../configurations/urls'
import { Button, Image, Spin } from "antd";
import { Gallery } from "react-grid-gallery";
import { Store } from 'react-notifications-component';

const CreateCollage = () => {
  let { id } = useParams()
  const [step, setStep] = useState(1);
  const [images, setImages] = useState([]);
  const [frameImages, setFrameImages] = useState([]);
  const [selectedFrame, setSelectedFrame] = useState([]);
  const [frameError, setFrameError] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [selectedImage, setSelectedImages] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [numberOfBlocks, setNumberOfBlocks] = useState();
  const [lastAdded, setLastAdded] = useState([]);
  
  useEffect(() => {
    if (!!id) {
      axios.get(createCollage + id + "/")
        .then(res => {
          setImages(inventoryImageSource(res.data['images']))
          setFrameImages(inventoryDataSource(res.data['frame_images']))
        })
        .catch(err => {

        })
    }
  }, [id])
  
  const handleNextStep = () => {
    setFrameError(null)
    const imagesArray = []
    frameImages.forEach(d => {
      if (d.isSelected){
        imagesArray.push({'url': d.src, 'urlWithOrder': d.imageWithOrder, 'id':d.id})
      }
      setNumberOfBlocks(d.numberOfBlocks)
    })
    if (imagesArray.length === 0){
      setFrameError('Please Select Frame Image')
    }else if (imagesArray.length > 1){
      setFrameError('Frame Images Cannot Be Multiple')
    }else{
      setSelectedFrame(imagesArray)
      setStep(step + 1);
    }
  }

  const handlePrevStep = () => {
    setStep(step - 1);
  }

  const inventoryImageSource = (data) => {
    let arr = []
    data.length > 0 && data.forEach((d, i) => {
      if (d) {
        arr.push({ "src": d.origional_url, "original":d.origional_url, "width": 300, "height": 200,
          tags: []
        })
      }
    })
    return arr
  }

  const inventoryDataSource = (data) => {
    let arr = []
    data.length > 0 && data.forEach((d, i) => {
      if (d) {
        arr.push({"src": d.image_url, "original":d.image_url,'numberOfBlocks':d.number_of_blocks, 'imageWithOrder':d.image_with_order, 'id':d.id, "width": 300, "height": 200})
      }
    })
    return arr
  }
  
  const handleSelect = (index) => {
    const nextImages = images.map((image, i) => {
      if(i===index){
        if(image.isSelected){
          image.tags = []
          image.isSelected = !image.isSelected
        }else{
          image.tags = [{title: 1, value: 1}]
          image.isSelected = !image.isSelected
        }
      }
    })
    setImages(addImages(images));
  };
  
  const handleFrmaeSelect = (index) => {
    const nextImages = frameImages.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setFrameImages(nextImages);
  };

  const checkAddedValue = (value) => {
    let value_available = true;
    let number_of_images = 0;
    images.map((image, i) => {
      if (image.isSelected){
        if(image.tags[0].value === value){
          value_available = false
        }
        number_of_images += 1;
      }
    })
    return {'valueAvailable': value_available, 'numberOfImages': number_of_images}
  }

  const handleClick = (index, item) => {
    // let arr = [];
    let order_array = Array.from({length: numberOfBlocks}, (_, i) => i + 1)
    let newValue = 0;
    let value_found = false;
    let numberOfImages = 0;
    order_array.map((value, i) => {
      if (!value_found){
      let result = checkAddedValue(value)
      if (result.valueAvailable){
        newValue = value;
        value_found = result;
      }
      numberOfImages = result.numberOfImages
    }
    })
    // lastAdded.map((val, i) => {
    //   arr.push(val)
    //   })
    // if (newValue <= numberOfBlocks){
    // arr.push(newValue)
    // }else{
    //   newValue = 1;
    // }
    images.map((image, i) => {
      if(i===index){
        if(image.tags.length > 0){
          let addedValue = image.tags[0].value
          if (addedValue < numberOfBlocks){
            newValue = addedValue+1
            image.tags = [{title: newValue, value: newValue}]
          }else{
            image.tags = []
            image.isSelected = !image.isSelected
          }
        }else{
          if (newValue !== 0 && numberOfImages < numberOfBlocks){
          image.tags = [{title: newValue, value: newValue}]
          image.isSelected = !image.isSelected
          }else{
            Store.addNotification({
              title: 'You can only select '+numberOfBlocks+' images',
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 3500,
                onScreen: true
              }
          });
          }
        }
      }
    })
    setImages(addImages(images));
    // setLastAdded(arr)
  }

  const addImages = (images) => {
    let arr = []
    images.map((image, i) => {
      arr.push(image)
    })
    return arr
  }

  const handleCreate = () => {
    setImageError(null)
    const imageIndexArray = [];
    const selectedImages = [];
    let errorText = '';
    images.map((image, index) => {
      if (image.isSelected){
        const indexValue = image.tags[0].value;
        if (imageIndexArray.length > 0){
          imageIndexArray.map((d, i) => {
            if (d === indexValue){
              errorText = 'No Two Images Can Have Same Order'
            }
          })
          imageIndexArray.push(indexValue)
        }else{
          imageIndexArray.push(indexValue)
        }
        selectedImages.push({url: image.src, index: indexValue})
      }
    })
    setSelectedImages(selectedImages)
    if (!errorText){
      if (selectedImages.length < numberOfBlocks){
        errorText = 'Select Atleast '+numberOfBlocks+' Images'
      }else if(selectedImages.length > numberOfBlocks){
        errorText = 'Select Only '+numberOfBlocks+' Images'
      }
    }
    if (!errorText){
      let frameImageArr = []
      let imagesArr = []
      selectedFrame.forEach((d, i) => {
        frameImageArr.push(d.id);
      })
      selectedImages.map((d, i) => {
        imagesArr.push({url: d.url, index: d.index})
      })
      setImageLoading(true)
      axios.post(createCollage + id + "/", {frame_image: frameImageArr, images: imagesArr})
            .then(res => {
              setImageLoading(false)
              Store.addNotification({
                title: 'Collage created successfully',
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2500,
                  onScreen: true
                }
            });
            setTimeout(() => {
              window.location.replace('/')
          }, 2500)
            })
            .catch(err => {
              setImageLoading(false)
              Store.addNotification({
                title: 'Something went wrong',
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 3500,
                  onScreen: true
                }
            });
            });
    }else{
      setImageError(errorText)
    }

  }

  return (
    <>
    { !!imageLoading &&
      <div className='loader'>
          <Spin />
          <h3 className="ml-3 text-white" >Creating Collage Please Wait...</h3>
      </div>
  }
    <div className="collage-main">
      <h6>Step {step} of 2</h6>
      {step === 1 && 
        <div className='collage-frames'>
          <p>Select Frame Image</p>
          <Gallery className="frame_image_gallery" images={frameImages} onSelect={handleFrmaeSelect} margin={5} />
          <Button type="primary" onClick={handleNextStep}>
            Next
          </Button>
          {frameError &&
            <p style={{display: 'inline-block'}} className="text-danger ml-3">
              {frameError}
            </p>}
        </div>
      }
      {step === 2 && (
        <div className='collage-images'>
          <p>Selected Frame Image</p>
          {selectedFrame.map(((d, i) => (
            <div>
              <Image
                preview={true}
                src={d.urlWithOrder}
                height={180}              
        
              >                    
              </Image>
            </div>
          )))}
          <p className='mt-3'>Select Vehicle Images (Click on image to set order)</p>
          <Gallery images={images} onClick={handleClick} onSelect={handleSelect} margin={5} />
          <Button onClick={handlePrevStep}>
            Back
          </Button>
          <Button type="primary" onClick={handleCreate}>
            Create
          </Button>
          {imageError &&
            <p style={{display: 'inline-block'}} className="text-danger ml-3">
              {imageError}
            </p>
          }
        </div>
      )}
    </div>
    </>
  )
}

export default CreateCollage