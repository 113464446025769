import {useState,useEffect} from 'react'
import axios from 'axios'
import { getSummary } from '../configurations/urls'
import { Table } from 'antd';
const { Column, ColumnGroup } = Table;

const SummaryTable = ({data}) => {
    const [tbData,setTbData]=useState([])
    const [loading,setLoading]=useState(true)
    
    useEffect(()=>{
        getTbData()
    },[])
    const getTbData = ()=>{
        setLoading(true)
        axios.get(`${getSummary}`)
            .then(res => {
                setTbData(res.data)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    return (
        <div>
            <Table dataSource={tbData} loading={loading} pagination={false}>
            <Column
                title='Client Name'
                dataIndex='dealer_name'
                key='dealer_name'
            />
            <Column
                title='Client Id'
                dataIndex='dealer_id'
                key='dealer_id'
            />
            <ColumnGroup title="Last 24 hours">
                <Column title="New" dataIndex="last_24_hours_new" key="last_24_hours_new" />
                <Column title="Used" dataIndex="last_24_hours_used" key="last_24_hours_used" />
                <Column title="Lot2" dataIndex="last_24_hours_second" key="last_24_hours_second" />
                <Column title="Total" dataIndex="last_24_hours_total" key="last_24_hours_total" />
            </ColumnGroup>
            <ColumnGroup title="Last 7 days">
                <Column title="New" dataIndex="last_7_days_new" key="last_7_days_new" />
                <Column title="Used" dataIndex="last_7_days_used" key="last_7_days_used" />
                <Column title="Lot2" dataIndex="last_7_days_second" key="last_7_days_second" />
                <Column title="Total" dataIndex="last_7_days_total" key="last_7_days_total" />
            </ColumnGroup>
            <ColumnGroup title="Last 30 days">
                <Column title="New" dataIndex="last_30_days_new" key="last_30_days_new" />
                <Column title="Used" dataIndex="last_30_days_used" key="last_30_days_used" />
                <Column title="Lot2" dataIndex="last_30_days_second" key="last_30_days_second" />
                <Column title="Total" dataIndex="last_30_days_total" key="last_30_days_total" />
            </ColumnGroup>
            <ColumnGroup title="Lifetime">
                <Column title="New" dataIndex="lifetime_new" key="lifetime_new" />
                <Column title="Used" dataIndex="lifetime_used" key="lifetime_used" />
                <Column title="Lot2" dataIndex="lifetime_second" key="lifetime_second" />
                <Column title="Total" dataIndex="lifetime_total" key="lifetime_total" />
            </ColumnGroup>
            </Table>
        </div>
    )
}

export default SummaryTable
