import React, { useEffect, useRef, useState } from "react";
import {
  CloseOutlined,
  UploadOutlined,
  RollbackOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { triggerBase64Download } from "react-base64-downloader";
import { useDispatch } from "react-redux";
import axios from "axios";
import { sendToEditor } from "../Actions";
import { Spin, Upload, Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { getImageSlider, saveProcessedImage } from "../configurations/urls";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery'
import ImageViwerDrag from "./ImageViwerDrag";
import { Store } from 'react-notifications-component';

const SliderImageViwer = ({ onClose, sliderImageData, link ,perviousImageViewer ,nextImageViewer ,nextRecord ,perivousRecord ,SliderImageId, currentPage, removed_record}) => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [imageEdited, setImageEdited] = useState("");
  const [imageUpdated, setImageUpdated] = useState("");
  const [imageMarked, setImageMarked] = useState("");
  const [isModalImageVisible , setisModalImageVisible]= useState(false);
  const [overlayImage, setOverlayImage] = useState("");
  const [overlayUrl, setOverlayUrl] = useState("");
  const dispatch = useDispatch();
  let history = useHistory();
  const [sliderIndex, setSliderIndex] = useState(0);
 const elementRef = useRef()
  function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }
  useEffect(() => {
    setOriginalImage("");
    setImageEdited("");
    setImageUpdated("");
    setImageMarked("");
    setOverlayImage("");
    setOverlayUrl("");
    if (!!sliderImageData.length) {
      let listss = document.querySelectorAll(".slick-current");
      if(!!listss && listss.length){
      setTimeout(()=>{
        listss[0].classList.add("current")
        
        handleSliderClick(sliderImageData[0] ,0)
      },400)
      }
    }
  }, [sliderImageData]);
  const returnCurrentImage = () => {
    for (let i in sliderImageData) {
      if (i == sliderIndex) {
       
        return sliderImageData[i].editted_image;
      }
    }
  };
  const download = () => {
    let stokeNumber =""
    setPending("download");
    for(let i in sliderImageData){
      if(sliderImageData[i].editted_image == originalImage){
    stokeNumber= sliderImageData[i].stock_number
      }
    }

    const currentImage = returnCurrentImage();
    toDataUrl(currentImage+"?v=1655290884560", function (myBase64) {
      let b64 = myBase64.replace(/^data:image\/jpeg;base64,/, "data:image/jpg;base64,");
      triggerBase64Download(b64, stokeNumber);
      setPending("");
    });
  };
  const revert = () => {
    setPending("revert");

    let data = "";
    for (let i in sliderImageData) {
      if (i == sliderIndex) {
        data = sliderImageData[i];
      }
    }
  
    axios
      .delete(
        `${saveProcessedImage}${
          data.image_id
        }/${data.owner}/`,
        {
          image_id: data.image_id,
          images_edited: data.images_edited,
        }
      )
      .then((res) => {

        // setUpdateImage(current.img)
        // setUpdateData()
        setPending("");
        // onClose();
        history.push(link);
        window.location.reload();
      })
      .catch((err) => {
     
        setPending("");
      });
  };
  const openEditor = async (a) => {
    let image = await axios.get(getImageSlider + a.image_id + "/");
    // let orignal = Buffer.from(image.data).toString("base64");
    let orignal = image.data['original_image'];
    // image = await axios.get(`${a.processed_image}`, {
    //   responseType: "arraybuffer",
    // });
    // let processed = Buffer.from(image.data).toString("base64");
    let processed = image.data['processed_image'];
    var doc = document.documentElement;
    const currentPosition =
      (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      localStorage.setItem("scroll-postion" , window.scrollY)
    window.localStorage.setItem("inventory-list-top", currentPosition);
    window.localStorage.setItem("current_image_id", image.data['image_order']);
    window.localStorage.setItem("current_csv_data_id", image.data['csv_data_id']);
    window.localStorage.setItem("currentInventoryPage", currentPage);
    dispatch(
      sendToEditor({
        orignalImage: `${orignal}`,
        removedImage: `${processed}`,
        link: link,
        imgName:
          a.editted_image.split("/")[a.editted_image.split("/").length - 1],
        dealerId: a.owner,
        original_image: a.original_image,
        images_edited: a.images_edited,
        image_id: a.image_id,
        image_updated: a.image_updated,
        csv_id: a.csv_data_id,
        current_id: a.image_order,
        original_url: a.link,
        stock_number: a.stock_number
      })
    );
    localStorage.setItem("pervious_route" , link)
    history.push("/editor");
  };
  const openEdit = () => {
    let data = "";
    for (let i in sliderImageData) {
      if (i == sliderIndex) {
        data = sliderImageData[i];
      }
    }
    openEditor(data);
    setLoading(true);
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const uploadImage = async (options) => {

    let data;
    for (let i in sliderImageData) {
      if (i == sliderIndex) {
        data = sliderImageData[i];
      }
    }
    setPending("upload");
    const { file } = options;
    let b64 = await toBase64(file);
    // b64 = b64.replace("data:image/png;base64,", "");
    b64 = b64.replace(/^data:image\/[a-z]+;base64,/, "");
    axios
      .post(
        `${saveProcessedImage}${
          data.image_id
        }/${data.owner}/`,
        {
          image_data: b64,
          image_id: data.image_id,
          images_edited: data.images_edited,
          link: data.link,
          image_updated: data.image_updated
        }
      )
      .then((res) => {
        setOriginalImage(res.data.editted_image);
        setImageEdited(res.data.images_edited);
        setImageUpdated(res.data.image_updated);
        setImageMarked(res.data.marked_for_rework);
        for(let i in sliderImageData){
          if(sliderImageData[i].original_image == res.data.original_image){
            sliderImageData[i].editted_image = res.data.editted_image
            sliderImageData[i].images_edited = res.data.images_edited
            sliderImageData[i].image_updated = res.data.image_updated
          }
        }
        // setUpdateImage(current.img)
        // setUpdateData()
        setPending("");
        // onClose();
        // history.push(link);
        // window.location.reload();
      })
      .catch((err) => {
     
        setPending("");
        Store.addNotification({
          title: err.response.data.message,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3500,
            onScreen: true
          }
      });
      });
  };
  const handleChange = (currentIndex) => {
   
    setSliderIndex(currentIndex);
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
  };
  const handleSliderClick = (data, i,e) => {
   if(!!e){
    let list = document.querySelectorAll(".current");
    for (var j = 0; j < list.length; ++j) {
     list[j].classList.remove('current');
   }
    e.currentTarget.parentElement.parentElement.classList.add('current');
  }
  
    setSliderIndex(i);
    setOriginalImage(data.editted_image);
    setImageEdited(data.images_edited);
    setImageUpdated(data.image_updated);
    setImageMarked(data.marked_for_rework);
    setOverlayImage(data.overlay_image);
    setOverlayUrl(data.overlay_url);
  };
  const mystyle = {
    border: "3px solid red"
  };
  const Reorder =()=>{
    window.location.pathname = "/reorder/"+SliderImageId
    localStorage.setItem("perviousRoute" , "/inventory-list")
  }
  return (
    <div className="img-viewer">
      <div className="overlay"></div>
      {loading ? (
        <div className="container">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <Spin />
            <h3 className="ml-3 text-white" style={{ zIndex: "222" }}>
              Opening Editor please wait...
            </h3>
          </div>
        </div>
      ) : (
        <div className="slider-section">
         {!!sliderImageData.length &&
          <div className="body">
            {!!perivousRecord &&  <div className="slider_navigation-icon previous" onClick={()=> perivousRecord && perviousImageViewer()}><DoubleLeftOutlined /></div>}
            {!!nextRecord &&  <div className="slider_navigation-icon next" onClick={()=> nextRecord && nextImageViewer()}><DoubleRightOutlined /></div>}
            <div className="close" onClick={onClose}>
              <CloseOutlined />
            </div>
            <div className="topbar">
              <div className="title">
                {" "}
                {sliderImageData.length > 0 ? sliderImageData[0].dealership_name : ""}<br />
                Stock # : {" "}
                {sliderImageData.length > 0 ? sliderImageData[0].stock_number : ""}
              </div>
              
            </div>
            <div className="slider-content">
            <div className="slider-single-img" style={{ border: removed_record == false ? imageMarked == true ?'3px solid #FFD700': imageUpdated == true ? '3px solid #7FFF00' : '3px solid #FF0000':'none'}}>
                {!!originalImage && 
                <div>
                  {!! overlayImage &&
                <div className='overlay-image'>
                  <img src={overlayUrl} />
                </div>
                }
                <div>
                  <img src={originalImage} alt="swwd" />
                </div>
                </div>}
                {!removed_record ?
                <div className="d-flex bottombar slider-btns">
                  <div className="slider-btns-inner">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => openEdit()}
                  >
                    Edit
                  </button>
                  </div>
                  <div className="slider-btns-inner">

                  <button
                    className="btn btn-sm btn-success mx-3"
                    disabled={pending === "download"}
                    onClick={() => download()}
                  >
                    Download
                  </button>
                  </div>
                  <div className="slider-btns-inner">
                  <Upload
                    customRequest={uploadImage}
                    maxCount={1}
                    showUploadList={false}
                  >
                    <Button
                      loading={pending === "upload"}
                      icon={<UploadOutlined />}
                    >
                      Replace
                    </Button>
                  </Upload>
                  </div>
                  <div className="slider-btns-inner">

                  <Button
                  
                    className="btn btn-sm btn-secondary mx-3"
                    disabled={imageEdited == false ? true : false}
                    danger={true}
                    onClick={() => revert()}
                    icon={<RollbackOutlined />}
                  >
                    Revert
                  </Button>
                  </div>
                  <div className="slider-btns-inner">
                  <button
                  
                    className="btn btn-sm btn-info"
                    onClick={() => Reorder()}
                    // icon={<RollbackOutlined />}
                  >
                    Reorder
                  </button>
                  </div>
                  <Modal className="" centered visible={isModalImageVisible} onCancel={()=> setisModalImageVisible(false)} closable footer={null} >
                    <ImageViwerDrag/>
                </Modal>
                </div>
                :null}
              </div>
              
              <div className="slider-multi-img">
                <Slider {...settings}>
                 
                    {sliderImageData.map((data, i) => (
                      
                      <div key={i} id={data.image_id} className="slider-slick" onClick={(e) => handleSliderClick(data, i,e)}>
                        <img src={data.original_image} alt="wdww" />
                      </div>
                    ))}
                </Slider>
              </div>
            </div>

            {/* <ImageGallery  autoPlay={false} onSlide={handleChange} items={sliderImageData.map((data) => { return { original: data.editted_image, thumbnail: data.original_image , id:data.id } })} /> */}
          </div>}
        </div>
      
      )}
    
    </div>
  );
};
export default withRouter(SliderImageViwer);
