import React from 'react'
import TableComp from './TableComp'
import { Form, Input, Button, Space, Popconfirm ,Modal, Tag, Spin} from 'antd'
import { withRouter } from 'react-router-dom'
import Highlighter from 'react-highlight-words';
import { SearchOutlined, DeleteOutlined ,EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { adminLink, sendCsvUser, startManualScheduler, updateDealerID, startSingleProcess } from '../configurations/urls';
import { Store } from 'react-notifications-component';
import AdminSetting from './AdminSetting';
import { Pagination } from 'antd'

class ClientTableColumn extends React.Component {

    state = {
        searchText: '',
        isModalVisible:false,
        setEditId:"",
        searchedColumn: '',
        type: localStorage.getItem('type'),
        isModalVisible: false,
        visible: false,
        index: 0,
        source: '',
        sorceData: this.props.dataSource,
        deleteSucess: "",
        otherSource: 1,
        processDisabled: this.props.pendingFiles,
        buttonLoading: false,
        isDealerModalVisible: false,
        currentDealerId: '',
        currentDealerIdValue: '',
        currentDealerName: '',
        imageLoading:false
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90, alignItems: 'center', display: 'inline-block' }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };
    openSingle = (val, id) => {
        this.props.history.push(`/client/${id}/${val}`)
    }
    columns = [

        {
            title: 'Dealer ID',
            key: 'DealerID',
            render: (record) =>
                <div>
                    {this.props.adminUser ?
                    <Button className='pl-0' type="link" onClick={() => this.setState({ currentDealerId:record.id, currentDealerIdValue: record.dealer_id, currentDealerName: record.dealer_name, isDealerModalVisible:true})}>
                        {record.dealer_id}
                    </Button>
                    :
                    <p>{record.dealer_id}</p>
                    }
                </div>
        },
        {
            title: 'Dealer Name',
            dataIndex: 'dealer_name',
            key: 'DealerName',
            ...this.getColumnSearchProps('dealer_name'),
            sorter: (a, b) => { return a.dealer_name.localeCompare(b.dealer_name) }
        },
        {
            title: 'Today',
            key: 'today',
            render: (record) =>
                <div>
                    {!!record.file_status ?
                        <Tag color={record.file_status == "PENDING" ? "warning" : "success"}> {record.file_status == "PENDING" ? "In Progress" : record.total_image_count}</Tag> :
                        <Tag color='default'>N/A</Tag>}
                </div>
        },
        {
            title: 'Last Import',
            dataIndex: 'last_import',
            key: 'Last Import',
            sorter: (a, b) => new Date(a.last_import) - new Date(b.last_import)
        },
        {
            title: 'Last Sent',
            dataIndex: 'last_sent',
            key: 'Last Sent',
            sorter: (a, b) => new Date(a.last_sent) - new Date(b.last_sent)
        },
        {
            title: 'Host',
            dataIndex: 'ftp_host',
            key: 'Host',
            sorter: (a, b) => a.ftp_host - b.ftp_host
        },
        {
            title: 'Port',
            dataIndex: 'ftp_port',
            key: 'Port',
            sorter: (a, b) => a.ftp_port - b.ftp_port
        },
        {
            title: 'Username',
            dataIndex: 'ftp_username',
            key: 'Username',
            sorter: (a, b) => { return a.ftp_username.localeCompare(b.ftp_username) }
        },
        {
            title: 'Password',
            dataIndex: 'ftp_password',
            key: 'Password',
            sorter: (a, b) => { return a.ftp_password.localeCompare(b.ftp_password) }
        },
        {
            title: 'User Host',
            dataIndex: 'user_added_ftp_host',
            key: 'User Host',
            sorter: (a, b) => a.user_added_ftp_host - b.user_added_ftp_host
        },
        {
            title: 'User Port',
            dataIndex: 'user_added_ftp_port',
            key: 'User Port',
            sorter: (a, b) => a.user_added_ftp_port - b.user_added_ftp_port
        },
        {
            title: 'User Username',
            dataIndex: 'user_added_ftp_username',
            key: 'User Username',
            sorter: (a, b) => { return a.user_added_ftp_username.localeCompare(b.user_added_ftp_username) }
        },
        {
            title: 'User Password',
            dataIndex: 'user_added_ftp_password',
            key: 'User Password',
            sorter: (a, b) => { return a.user_added_ftp_password.localeCompare(b.user_added_ftp_password) }
        },
        (this.props.adminUser ?
            {
                title: 'Start Process',
                key: 'operation',
                fixed: 'right',
                // width: 100,
                render: (record) =>
                        <div className='process_buttons'>
                            <Popconfirm className='send-popup' title="Are you sure you want to start this process ?" onConfirm={() => this.startSingleScheduler(record)} style={{ cursor: 'pointer' }} >
                                <Button className='start-process-button' disabled={record.file_status == "PENDING"}>Start</Button>
                            </Popconfirm>
                        </div>
            }:
            {
                title: 'Start Process',
                key: 'operation',
                fixed: 'right',
                hidden: true,
                // width: 100,
                render: (record) =>
                        <div className='process_buttons'>
                            <Popconfirm className='send-popup' title="Are you sure you want to start this process ?" onConfirm={() => this.startSingleScheduler(record)} style={{ cursor: 'pointer' }} >
                                <Button className='start-process-button' disabled={record.file_status == "PENDING"}>Start</Button>
                            </Popconfirm>
                        </div>
            }
        ),
        (this.props.adminUser ?
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            // width: 100,
            render: (record) =>
                <div className='d-flex align-items-center action_wrap'>
                
                    <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => this.onDelete(record)} style={{ cursor: 'pointer' }} >
                        <DeleteOutlined />
                    </Popconfirm>
                    <EditOutlined onClick={()=>  {this.setState({isModalVisible: true ,setEditId:record.dealer_id})}}  className="ml-2" />
             
                </div>
        }:
        {
            title: 'Action',
            key: 'operation',
            fixed: 'right',
            hidden: true,
            // width: 100,
            render: (record) =>
                <div className='d-flex align-items-center action_wrap'>
                
                    <Popconfirm title="Are you sure you want to delete this user?" onConfirm={() => this.onDelete(record)} style={{ cursor: 'pointer' }} >
                        <DeleteOutlined />
                    </Popconfirm>
                    <EditOutlined onClick={()=>  {this.setState({isModalVisible: true ,setEditId:record.dealer_id})}}  className="ml-2" />
             
                </div>
        }
        ),
        {
            title: 'Send CSV',
            key: 'operation',
            fixed: 'right',
            // width: 100,
            render: (record) =>
                <div className='d-flex align-items-center action_wrap'>
                    {!!record.files_available ?
                        <Popconfirm className='send-popup' title="Are you sure you want to send file to this user ?" onConfirm={() => this.onSendCsv(record)} style={{ cursor: 'pointer' }} >
                            <Button type="primary" className="mr-2">Send</Button></Popconfirm>:"N/A"}
                </div>
        }
    ].filter(item => !item.hidden);
   
    onDelete = (a) => {
        axios.delete(`${adminLink}${a.dealer_id}/`)
            .then(res => {
                Store.addNotification({
                    title: "Deleted successfully.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2500,
                      onScreen: true
                    }
                  });
                      setTimeout(() => {
                        window.location.reload();
                }, 2500)
             
                // const data = this.state.sorceData.filter(item => item.dealer_id != a.dealer_id);
                // this.setState({ sorceData: data });
                // this.setState({deleteSucess:true})
                // setTimeout(() => {
                //     this.setState({deleteSucess:""})
                // }, 2000)
            }).catch(err => {
               
            })
       
    }
    onSendCsv = (a) => {
        this.setState({imageLoading: true})
        axios.post(`${sendCsvUser}${a.dealer_id}/`)
            .then(res => {
                Store.addNotification({
                    title: "Csv send successfully.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2500,
                      onScreen: true
                    }
                  });
                      setTimeout(() => {
                        window.location.reload();
                }, 2500)
                this.setState({imageLoading: false})
            }).catch(err => {
                Store.addNotification({
                    title: "Something went wrong",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 2500,
                      onScreen: true
                    }
                  });
                      setTimeout(() => {
                        window.location.reload();
                }, 2500)
                this.setState({imageLoading: false})
            })
    }
    handleCancel = () => {
        this.setState({ isModalVisible: true })
    };
    showModal = () => {
        this.setState({ isModalVisible: true })
    };
    handleTriggle = () => {
        this.setState(prevState => ({ visible: !prevState.visible }))
    }
    componentDidMount() {
        this.setState({ source: this.props.dataSource });
        this.setState({ otherSource: this.props.otherData });
        this.setState({ processDisabled:this.props.pendingFiles });
    }
    componentDidUpdate(prevPros, prevState) {
        if (prevPros.otherData.length !== this.props.otherData.length){
            this.setState({ otherSource: this.props.otherData  })
        }
        if (prevPros.dataSource.length !== this.props.dataSource.length){
            this.setState({ source: this.props.dataSource })
            this.setState({ processDisabled:this.props.pendingFiles });
        }
    }
    handlepagination = (page) => {
        axios.get(`${adminLink}?page=${page}`)
            .then(res => {

                this.setState({ source: res.data['data'] })
                this.setState({ otherSource: this.otherDataSources(res.data) })
                this.setState({ processDisabled:res.data['pending_files'] });

            })
            .catch(err => {
               
            })
};
dataSources = (data) => {
    let arr = []
    data.length > 0 && data.forEach((d, i) => {
        if (d.images) {
            const tag = '?v=' + Date.now()
            d.images.forEach(img => {
          
                arr.push({ ...d, 'images': '', 'editted_image': !!img.editted_image?`${img.editted_image}${tag}`:"", 'original_image': !!img.original_image?`${img.original_image}${tag}`:"", 'processed_image': !!img.processed_image?`${img.processed_image}${tag}`:"" ,'image_id':!!img.image_id?img.image_id:"" ,'images_edited':!!img.images_edited?img.images_edited:"",'itemsToShow': 2,
                'expanded': false})
            })
        } else {
            arr.push({...d,'itemsToShow': 2,
            'expanded': false})
        }
    })
    return arr
}
otherDataSources = (data) => {
    let arr = {page:data['page'], total:data['total'], page_size:data['page_size']}
    return arr
}
startScheduler = () => {
    this.setState({buttonLoading:true})
    axios.get(`${startManualScheduler}`)
        .then(res => {
            this.setState({buttonLoading:false})
            this.setState({processDisabled:true})
            Store.addNotification({
                title: "Process started successfully.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2500,
                  onScreen: true
                }
              });
                  setTimeout(() => {
                    window.location.reload();
            }, 2500)
        }).catch(err => {
          console.log(err)
        })
}
onFinish = (values) => {
    this.setState({imageLoading: true, isDealerModalVisible:false})
    let id = this.state.currentDealerId;
    let success_text = 'Dealer info updated successfully';
    axios.put(`${updateDealerID}${id}/`, values)
        .then(res => {
            this.setState({imageLoading: false})
            Store.addNotification({
                title: success_text,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
            setTimeout(() => {
                window.location.reload();
            }, 2500)
        })
        .catch(err => {
            this.setState({imageLoading: false})
            let errorText = "";
            if (err.response.data.message){
                errorText = err.response.data.message
            }else{
                errorText = "Something went wrong"
            }
            Store.addNotification({
                title: errorText,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 2500,
                    onScreen: true
                }
            });
        })
}

startSingleScheduler = (a) => {
    this.setState({imageLoading: true})
    axios.post(`${startSingleProcess}${a.dealer_id}/`)
        .then(res => {
            Store.addNotification({
                title: "Scheduler started successfully.",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2500,
                  onScreen: true
                }
              });
                  setTimeout(() => {
                    window.location.reload();
            }, 2500)
            this.setState({imageLoading: false})
        }).catch(err => {
            let errorText = "";
            if (err.response.data.message){
                errorText = err.response.data.message
            }else{
                errorText = "Something went wrong"
            }
            Store.addNotification({
                title: errorText,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 2500,
                  onScreen: true
                }
              });
                  setTimeout(() => {
                    window.location.reload();
            }, 2500)
            this.setState({imageLoading: false})
        })
    }

    render() {
        return (
            <div>
                { !!this.state.imageLoading &&
                <div className='loader'>
                    <Spin />
                    <h3 className="ml-3 text-white" >Please Wait...</h3>
                </div>
            }

                {/* <Editor original={this.state.originalImage} removed={this.state.removedImage} /> */}
                {this.state.deleteSucess &&
                    <p className="text-success ml-3">
                        Delete Successfully
                    </p>
                }
                {/* <div className='process_buttons'>
                    <Button style={{ display:'inline-block'}} type="primary" className="mb-3 ml-3" onClick={() => this.startScheduler()} disabled={this.state.processDisabled} loading={this.state.buttonLoading}>Start Process</Button>
                </div> */}
                <TableComp loading={this.props.loading} columns={this.columns} dataSource={this.state.source} />
                {(this.state.otherSource.page) && (
                <div className="pagination-container">
                    <Pagination current={this.state.otherSource.page} showSizeChanger={false} onChange={this.handlepagination} pageSize={100} defaultPageSize={100} total={this.state.otherSource.total} />
                </div>
                )}
                <Modal className="list-user-editmodal" centered visible={this.state.isModalVisible} onCancel={()=> this.setState({isModalVisible:false})} closable footer={null} >
                    <div className='list-user-editmodal-inner'>
                        <AdminSetting id={this.state.setEditId}/>
                    </div>
                </Modal>
                <Modal destroyOnClose={true} title={<h5>Update Dealer Info</h5>} className='multiBtn_modal' closable={true} onCancel={()=> this.setState({isDealerModalVisible:false, currentDealerId:'', currentDealerIdValue: '', currentDealerName:'' })} centered footer={null} visible={this.state.isDealerModalVisible} >
                <Form name="nest-messages" layout="horizontal" onFinish={this.onFinish}>
                        <Form.Item initialValue={this.state.currentDealerIdValue} name="dealer_id" label="Dealer ID">
                        <Input />
                        </Form.Item>
                        <Form.Item initialValue={this.state.currentDealerName} name="dealer_name" label="Dealer Name">
                        <Input />
                        </Form.Item>
                    <div className='multiBtn_modal-btnwrap'>
                        <Button type="primary" htmlType="submit" style={{display:'inline-block'}}>
                            Update
                        </Button>
                    </div>
                </Form>
                </Modal>

            </div>
            
        )
    }
}

export default withRouter(ClientTableColumn)
