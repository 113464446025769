import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, withRouter } from 'react-router-dom';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UnorderedListOutlined,
    UploadOutlined,
    ScissorOutlined,
    UserOutlined,
    FileImageOutlined,
    FundOutlined,
    LogoutOutlined,
    DownloadOutlined,
    ScheduleOutlined,
    BorderOuterOutlined
} from '@ant-design/icons';
import ShowingCsv from './ShowingCsv';
import ManualCutout from './ManualCutout';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../redux/actions';
import { adminLink, allProcessedData, getInventoryImage, managerLink, getFrameImages, getOverlayImages } from '../configurations/urls';
import axios from 'axios'
import ClientTableColumn from './ClientTableColumn';
import InventoryImages from './InventoryImages';
import AdminSummary from './AdminSummary';
import ImageViwerDrag from './ImageViwerDrag';
import BulkImageUpload from './BulkImageUpload';
import MarkImages from './MarkImages';
import ManagerTableColumn from './ManagerTableColumn'
import CollageFrameTableColumn from './CollageFrameTableColumn'
import OverlayTableColumn from './OverlayTableColumn'
import CreateCollage from './CreateCollage'
import SummaryTable from './SummaryTable';

const { Header, Sider, Content } = Layout;

const Home = (props) => {
    const user = useSelector(state => state.user.user)
    const dispatch = useDispatch()
    const [users, setUsers] = useState([])
    const [otherUsers, setOtherUsers] = useState([])
    const [data, setData] = useState([])
    const [otherData, setOtherData] = useState([])
    const [inventoryImage, setInventoryData] = useState([])
    const [collapsed, setCollapsed] = useState(false);
    const [active, setActive] = useState(null);
    const [loading, setLoading] = useState("")
    const [loadingUsers, setLoadingUsers] = useState(true)
    const [updateData,setUpdateData]=useState(false)
    const [updateImage,setUpdateImage]=useState('')
    const [pendingFiles, setPendingFiles] = useState(false)
    const [adminUser, setAdminUser] = useState(false)
    const [allUsers, setAllUsers] = useState([])
    const { pathname } = useLocation();
    const fetchUsers = () => {
        axios.get(adminLink)
            .then(res => {
                setPendingFiles(res.data['pending_files'])
                setLoadingUsers(false)
                setUsers(res.data['data'])
                setOtherUsers(otherDataSource(res.data))
                setAllUsers(res.data['all_users'])
            })
            .catch(err => {
                setLoadingUsers(false)
               
            })
    }
    const fetchStaffUsers = () => {
        axios.get(managerLink)
            .then(res => {
                setUsers(res.data['data'])
                setOtherUsers(otherDataSource(res.data))
            })
            .catch(err => {
                console.log(err)
               
            })
    }
    const fetchFrameImages = () => {
        axios.get(getFrameImages)
            .then(res => {
                setUsers(res.data['data'])
                setOtherUsers(otherDataSource(res.data))
            })
            .catch(err => {
                console.log(err)
               
            })
    }
    const fetchOverlayImages = () => {
        axios.get(getOverlayImages)
            .then(res => {
                setUsers(res.data['data'])
                setOtherUsers(otherDataSource(res.data))
            })
            .catch(err => {
                console.log(err)
               
            })
    }
    // const fetchImages = () => {

    //     axios.get(allProcessedData)
    //         .then(res => {
                
    //             setData(dataSource(res.data['data']))
    //             setOtherData(otherDataSource(res.data))
    //             setLoading(false)
             
    //             //dispatch(setAllProcessedCsv(res.data))
    //         })
    //         .catch(err => {
    //             setLoading(false)
                
    //         })
    // }
    // const fetchInventoryImages = () => {

    //     axios.get(`${getInventoryImage}`)
    //         .then(res => {
    //             setInventoryData(inventoryDataSource(res.data))
    //             setLoading(false)
    //             //dispatch(setAllProcessedCsv(res.data))
    //         })
    //         .catch(err => {
    //             setLoading(false)
             
    //         })
    // }
    // const dataSource = (data) => {
    
    //     let arr = []
    //     data.length > 0 && data.forEach((d, i) => {
         
    //         if (d.images) {
             
    //             const tag = '?v=' + Date.now()
    //             d.images.forEach(img => {
    //                 arr.push({ ...d, 'images': '', 'editted_image': !!img.editted_image?`${img.editted_image}${tag}`:"", 'original_image': !!img.original_image?`${img.original_image}${tag}`:"", 'processed_image': !!img.processed_image?`${img.processed_image}${tag}`:"" ,'image_id':!!img.image_id?img.image_id:"" ,'images_edited':!!img.images_edited?img.images_edited:"" , "itemsToShow": 2,
    //                 "expanded": false})
    //             })
    //         } else {
    //             arr.push({...d,'itemsToShow': 2,
    //             'expanded': false})
    //         }
    //     })
    //     return arr
    // }
    // const inventoryDataSource = (data) => {

    //     let arr = []
    //     data.length > 0 && data.forEach((d, i) => {
         
    //         if (d.images) {
    //             d.images.forEach(img => {
    //                 arr.push({ ...d, 'images': '', 'editted_image': !!img.editted_image?`${img.editted_image}`:"", 'original_image': !!img.original_image?`${img.original_image}`:"", 'processed_image': !!img.processed_image?`${img.processed_image}`:"" ,
    //                 'stock_number': !!img.stock_number?`${img.stock_number}`:""})
    //             })
    //         } else {
    //             arr.push({...d,'itemsToShow': 2,
    //             'expanded': false})
    //         }
    //     })
    //     return arr
    // }
    const otherDataSource = (data) => {
        let arr = {page:data['page'], total:data['total'], pageSize:data['page_size']}
        return arr
    }
    
    useEffect(() => {
      
        /* if (pathname === '/clients') {
            setActive('1')
        } else */
        // if(pathname =='/inventory-images'){
        // fetchInventoryImages()
        // }
        setData([])
        setOtherData([])
       if(pathname =='/users' || pathname =='/inventory-summary' || pathname =='/admin-summary' || pathname == '/download-summary'){
        fetchUsers()
       }
       if(pathname == '/staff-users'){
        fetchStaffUsers()
       }
       if(pathname == '/collage-frames') {
        fetchFrameImages()
       }
       if(pathname == '/overlay') {
        fetchOverlayImages()
       }
        // fetchImages()
 
        if(pathname==='/'){
            props.history.push('/inventory-list')
        }else
        setActive(pathname)
        setUpdateData(false)
       
    }, [pathname,updateData===true])
    useEffect(()=>{
    return()=>{
        setActive(pathname)
        // localStorage.removeItem("is_scrool")
    }
    },[])
    useEffect(() => {
        if (user.is_admin === false && user.user_type === 'Dealer') {
            props.history.push('/public')
        }
        setAdminUser(user.is_admin)
    }, [])
    const toggle = () => {
        setCollapsed(!collapsed)
    };
    const changeLink = (e) => {
     
     
        /* if (e.key === '1') {
            props.history.push('/clients')
        } else */

         if (e.key === '/logout') {
            dispatch(logoutUser())
            window.location.replace('/login')
        }
        props.history.push(e.key)
    }
 
    const showContent = () => {
  
        /* if (active === '1') {

            return <ListUsers user={user} />
        } else  */
     
        if (active === '/inventory-list' || active === '/listusers' || active === '/inventory-images' || active === '/inventory-summary') {
            return <ShowingCsv data={data} otherData={otherData} otherUsers={otherUsers} updateImage={updateImage} setUpdateImage={setUpdateImage}  users={users} setUpdateData={()=>setUpdateData(true)} loadingUsers={loadingUsers} loading={loading} />
        } else if (active === '/users') {
            return <ClientTableColumn dataSource={users} otherData={otherUsers} loadingUsers={loadingUsers} pendingFiles={pendingFiles} adminUser={adminUser}/>
        } else if (active === '/images/') {
            return <InventoryImages dataSource={inventoryImage} updateImage={updateImage} setUpdateImage={setUpdateImage} setUpdateData={()=>setUpdateData(true)} link="/images/" loading={loading} />
        } else if (active === '/cutout') {
            return <ManualCutout />
        } else if (active === '/admin-summary' || active === '/download-summary') {
            return <AdminSummary users={users} allUsers={allUsers} />
        }
        else if (window.location.pathname.match("/reorder")){
            return <ImageViwerDrag/>
        }
        else if (window.location.pathname.match("/upload")){
            return <BulkImageUpload/>
        }
        else if (window.location.pathname.match("/review-images")){
            return <MarkImages/>
        }
        else if (window.location.pathname.match("/staff-users")){
            return <ManagerTableColumn dataSource={users} otherData={otherUsers}/>
        }
        else if (window.location.pathname.match("/collage-frames")){
            return <CollageFrameTableColumn dataSource={users} otherData={otherUsers}/>
        }
        else if (window.location.pathname.match("/overlay")){
            return <OverlayTableColumn dataSource={users} otherData={otherUsers}/>
        }
        else if (window.location.pathname.match("/collage")){
            return <CreateCollage/>
        }
    }
    return (
        <>
            {active !== null ?
                <Layout>
                    <Sider trigger={null} collapsible collapsed={collapsed}>
                        <div className="logo">
                            {!collapsed && <div>
                                <div>
                                    <small className="text-white mb-0">{user && user.dealer_name}</small>
                                </div>
                                {adminUser ?
                                <div>
                                    <small className="text-white mb-0">{user && user.dealer_id}</small>
                                </div>
                                : null}

                            </div>}
                        </div>
                        <Menu className='main_sidebar_sticky' theme="dark" mode="inline" onClick={changeLink} defaultSelectedKeys={active} selectedKeys={[active]}>
                            <Menu.Item key="/inventory-list" icon={<UnorderedListOutlined />}>
                                Inventory Lists
            </Menu.Item>
            {adminUser ?
                            <Menu.Item key="/users" icon={<UserOutlined />}>
                                List Users
            </Menu.Item>
            :
            <Menu.Item key="/users" icon={<UserOutlined />}>
                                List Dealers
            </Menu.Item>
            }
            {adminUser || user.user_type == "Editor" ?
                            <Menu.Item key="/cutout" icon={<ScissorOutlined />}>
                                Manual Cutout
            </Menu.Item>
            :null}
            {adminUser ?
                            <Menu.Item key="/admin-summary" icon={<FundOutlined />}>
                                Summary
            </Menu.Item>
            :null}
            {adminUser || user.user_type == "Editor" ?
            <Menu.Item key="/images/" icon={<DownloadOutlined />}>
                                Bulk Download
            </Menu.Item>
            :null}
            {adminUser || user.user_type == "Editor" ?
            <Menu.Item key="/upload" icon={<UploadOutlined />}>
                                Bulk Upload
            </Menu.Item>
            :null}
            <Menu.Item key="/review-images" icon={<ScheduleOutlined />}>
                                Review Images
            </Menu.Item>
            {adminUser ?
            <Menu.Item key="/staff-users" icon={<UserOutlined />}>
                                Other Users
            </Menu.Item>
            :null}
            <Menu.Item key="/collage-frames" icon={<FileImageOutlined />}>
                                Frames
            </Menu.Item>
            <Menu.Item key="/overlay" icon={<BorderOuterOutlined />}>
                                Overlay
            </Menu.Item>
        
                            <Menu.Item key="/logout" icon={<LogoutOutlined />}>
                                Logout
            </Menu.Item>
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background" style={{ padding: 0 }}>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: 'trigger',
                                onClick: toggle,
                            })}
                        </Header>
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '24px 16px',
                                padding: 24,
                                minHeight: 'calc(100vh - 112px)',

                            }}
                        >
                            
                            {showContent()}


                        </Content>
                    </Layout>
                </Layout > : null}
        </>
    )
}

export default withRouter(Home)
