import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select } from 'antd';
import axios from 'axios'
import { adminLink, managerEditLink, managerAddLink, getAllDealers} from '../configurations/urls';

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};
const {Option} = Select;
const ClientSetting = ({ active, id, selectedDealers, userType }) => {
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [form] = Form.useForm();
    const [othersValue , setOthersValue] = useState([])
    const [userDropdown, setUserDropdown] = useState([])
    const [clientDrop, setClientDrop] = useState(selectedDealers)
    const [userDrop, setUserDrop] = useState(userType)
    
    useEffect(() => {
        if (id) {        
            axios.get(`${managerEditLink}${id}/`)
                .then(async res => {
                    let data = { ...res.data }
                    setImageStates(data)
                })
                .catch(err => {})
        }

    }, [id])
    useEffect(() => {
        let url = '';
        if(userDrop){
        if (id) {
            url = `${getAllDealers}?client_id=${id}&user_type=${userDrop}`
        }else{
            url = `${getAllDealers}?user_type=${userDrop}`
        }
        axios.get(`${url}`)
            .then(res => {
                setUserDropdown(res.data['data'])
            })
            .catch(err => {
               
            })
        }
}, [userDrop])

    const setImageStates = async (data) => {
        form.setFieldsValue({ user: data })

    }

    const onFinish = (values) => {
        setError('')
        setSuccess(null)
        if (values["password"] && !values["confirm_password"]){
        setError('Please confirm your password')
        return;
        }
        const userData = {
            dealer_id: 'client',
            email: values.user["email"],
            dealer_name: values.user["dealer_name"],
            address: values.user["address"],
            phone: values.user["phone"],
            state: values.user["state"],
            city: values.user["city"],
            zip_code: values.user["zip_code"],
            website: values.user["website"],
            password: values["password"],
            confirm_password: values["confirm_password"],
            dealers_assigned: values.user["dealers_assigned"],
            user_type: values.user["user_type"]
        };
        let put
        if (id) {
            put = axios.put(`${managerEditLink}${id}/`, userData)
        }
        else{
            put = axios.post(`${managerAddLink}`, userData)
        }

        put.then(res => {
            setSuccess(true)
            setTimeout(() => {
                setSuccess(null)
            }, 2000)


        })
            .catch(err => {
                if(err.response.data.password){
                    
                    setError(err.response.data.password[0])
               
                 // setMessage('There is something wrong') 
             }else if(err.response.data.email){
                setError('Email should be unique') 
             }
             else{
                setError('There is something wrong') 
             }
            })
    };
    const handleChange = (value) => {
        setClientDrop(value);
    }
    const handleUserChange = (value) => {
        setUserDropdown([])
        if (value != userType){
            setClientDrop([]);
            setUserDrop(value);
        }else{
            setClientDrop(selectedDealers)
            setUserDrop(value);
        }
        // let url = '';
        // if (id) {
        //     url = `${getAllDealers}?client_id=${id}&user_type=${value}`
        // }else{
        //     url = `${getAllDealers}?user_type=${value}`
        // }
        // axios.get(`${url}`)
        //     .then(res => {
        //         setUserDropdown(res.data['data'])
        //     })
        //     .catch(err => {
               
        //     })
    }
    const inputConfig = {
        rules: [
            {
                required: true,
                message: 'This field is required!',
            },
        ],
    };
    const emailConfig={
        rules: [
            {
                type: 'email',
                message: 'This field is required!',
              },
            {
              required: true,
              message: 'This field is required!',
            },
          ],
      }
      const confirmConfig={
        rules: [
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ],
      }
    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="box">
                            <Form name="nest-messages" form={form} layout="horizontal" onFinish={onFinish} validateMessages={validateMessages}>
                                <h5>User Information</h5>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'dealer_name']} {...inputConfig} label="Name">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'address']} {...inputConfig} label="Address">
                                            <Input />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'email']} label="Email" {...emailConfig}>
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'city']} {...inputConfig} label="City">
                                            <Input />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'phone']} {...inputConfig} label="Phone">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'state']} {...inputConfig} label="State">
                                            <Input />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'website']} {...inputConfig} label="Website">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Item name={['user', 'zip_code']} {...inputConfig} label="Zip">
                                            <Input />
                                        </Form.Item>
                                    </div>


                                </div>
                                <div className="row">
                                <div className="col-md-6">
                                <Form.Item label="Password" rules={!id ? [{required:true, message:"This field is required"}] :""} name='password'>
                                <Input.Password />
                            </Form.Item>
                            </div>
                                <div className="col-md-6">
                                <Form.Item label="Confirm Password" dependencies={['password']} rules= {!id ? [
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                        },
                                    }),
                                    ]
                                    :
                                    [
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                            },
                                        }),
                                        ]} name='confirm_password'>
                                <Input.Password />
                            </Form.Item>
                            </div>
                            </div>
                                <div className='row mb-3'>
                                <div className='col-md-6'>
                                    {/* <h6 className='mb-2' style={{display:'inline-block'}}>Select User Type</h6> */}
                                    <Form.Item name={['user', 'user_type']} {...inputConfig} labelCol={{ span: 24 }} label="Select User Type">
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Select User Type"
                                            value={userDrop}
                                            onChange={handleUserChange}
                                            optionLabelProp="label"
                                        >
                                            <Option value="ClientManager" key="1" label="Client Manager">Client Manager</Option>
                                            <Option value="Editor" key="2" label="Editor">Editor</Option>
                                        </Select>
                                        </Form.Item>
                                    </div>
                                    <div className='col-md-6'>
                                    {/* <h6 className='mb-2' style={{display:'inline-block'}}>Select Dealer</h6> */}
                                    <Form.Item name={['user', 'dealers_assigned']} labelCol={{ span: 24 }} {...inputConfig} label="Select Dealers">
                                        <Select
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Select Dealers"
                                            value={clientDrop}
                                            onChange={handleChange}
                                            optionLabelProp="label"
                                            className='select-client-dealers'
                                        >
                                            {userDropdown && userDropdown.map(((item , i) => (
                                                <Option value={item.id} key={i} label={item.dealer_name}>{item.dealer_name} ({item.dealer_id})</Option>
                                            )))}
                                        </Select>
                                        </Form.Item>
                                    </div>
                                </div>
                                
                                <Form.Item>
                                    <div className="d-flex">
                                        <Button type="primary" htmlType="submit">
                                            {!id ?
                                            "Add User"
                                            :"Update Info"}
                                        </Button>
                                        {error && <p className="text-danger ml-3">
                                            {error}
                                        </p>}
                                        {success && <p className="text-success ml-3">
                                            Information Successfully Updated
                                        </p>}
                                    </div>
                                </Form.Item>
                            </Form>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(ClientSetting)
