import { useState, useEffect } from 'react'
import TableColumn from './TableColumn';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import {  FundOutlined, UnorderedListOutlined, FileImageOutlined } from '@ant-design/icons';
import { useLocation,useHistory } from 'react-router-dom';
import SummaryTable from './SummaryTable';
import InventoryImages from './InventoryImages';

const { TabPane } = Tabs;
const ShowingCsv = ({ data, otherData, otherUsers, loading, users, loadingUsers,setUpdateData,updateImage,setUpdateImage }) => {
    const user = useSelector(state => state.user)
    const [active, setActive] = useState(null);
    const { pathname } = useLocation();
    const history = useHistory();

    useEffect(() => {
        setActive(pathname.replace('/',""))
    }, [pathname])

    const changeTab=(tab)=>{
        history.push(`/${tab}`)
        setActive(tab)
    }
    return (
        <>
        {/* <Tabs defaultActiveKey="client" activeKey={active} onChange={changeTab}>
            <TabPane
                tab={
                    <span>
                        <UnorderedListOutlined />
                        Inventory Lists
                    </span>
                }
                key="inventory-list"
            > */}
                {<TableColumn dataSource={data} otherDataSource={otherData} updateImage={updateImage} setUpdateImage={setUpdateImage} setUpdateData={setUpdateData} user={user} loading={loading} />}
            {/* </TabPane>
            <TabPane
                tab={
                    <span>
                        <FundOutlined />
                        Summary
                    </span>
                }
                key="inventory-summary"
            >
                <SummaryTable data={users} />
            </TabPane> */}
            {/* <TabPane
                tab={
                    <span>
                        <FileImageOutlined />
                        Inventory Images
                    </span>
                }
                key="inventory-images"
            >
                <InventoryImages updateImage={updateImage} setUpdateImage={setUpdateImage} setUpdateData={setUpdateData} loading={loading} link="/inventory-images" />
            </TabPane> */}
        {/* </Tabs> */}
        </>
    )
}

export default ShowingCsv
